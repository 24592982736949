import { Injectable } from '@angular/core';
import { Badge } from '../models/badge';
import { Role } from '../models/role';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private static languagePath = 'config.language';
  private static tokenPath = 'auth.token';
  private static userPath = 'auth.user';
  private static redirectUrlPath = 'redirect.url';

  //todo: better caching of known objects
  private static cacheBadgesPath = 'cache.badges';
  private static cachePeoplePath = 'cache.people';
  private static cacheRolesPath = 'cache.roles';
  private static cacheSupervisorsPath = 'cache.supervisors';

  constructor() { }

  public setLanguage(language: string): void {
    window.localStorage.setItem(StorageService.languagePath, language);
  }

  public getLanguage(): string {
    return window.localStorage.getItem(StorageService.languagePath);
  }

  public removeLanguage(): void {
    window.localStorage.removeItem(StorageService.languagePath);
  }

  public getAuthorizationToken(): string {
    let token = window.localStorage.getItem(StorageService.tokenPath);

    return token;
  }

  public setAuthorizationToken(token): void {
    window.localStorage.setItem(StorageService.tokenPath, token);
  }

  public removeAuthorizationToken(): void {
    window.localStorage.removeItem(StorageService.tokenPath);
  }

  public setUser(user: User): void {
    window.localStorage.setItem(StorageService.userPath, JSON.stringify(user));
  }

  public getUser(): User {
    let user = window.localStorage.getItem(StorageService.userPath)
    return !user? null : <User>JSON.parse(user);
  }

  public removeUser() {
    window.localStorage.removeItem(StorageService.userPath);
  }

  public setRedirectUrl(url){
    window.localStorage.setItem(StorageService.redirectUrlPath,url);
  }

  public getRedirectUrl(){
    return window.localStorage.getItem(StorageService.redirectUrlPath);
  }

  public removeRedirectUrl(){
    window.localStorage.removeItem(StorageService.redirectUrlPath);
  }

  /* ============================ TEST CACHE ===================== */

  /** sets cached badges in session storage */
  public setCacheBadges(badges: Badge[]): void {
    window.sessionStorage.setItem(StorageService.cacheBadgesPath, JSON.stringify(badges));
  }

  /** gets cached badges in session storage */
  public getCacheBadges(): Badge[] {
    let badges = window.sessionStorage.getItem(StorageService.cacheBadgesPath)
    return !badges ? [] : <Badge[]>JSON.parse(badges);
  }

  /** removes cached badges in session storage */
  public removeCacheBadges() {
    window.sessionStorage.removeItem(StorageService.cacheBadgesPath);
  }

  /** sets cached users in session storage */
  public setCacheUsers(people: User[]): void {
    window.sessionStorage.setItem(StorageService.cachePeoplePath, JSON.stringify(people));
  }

  /** gets cached users in session storage */
  public getCacheUsers(): User[] {
    let users = window.sessionStorage.getItem(StorageService.cachePeoplePath);
    return !users ? [] : <User[]>JSON.parse(users);
  }

  /** removes cached users in session storage */
  public removeCacheUsers() {
    window.sessionStorage.removeItem(StorageService.cachePeoplePath);
  }

  /** sets cached roles in session storage */
  public setCacheRoles(roles: Role[]): void {
    window.sessionStorage.setItem(StorageService.cacheRolesPath, JSON.stringify(roles));
  }

  /** gets cached roles in session storage */
  public getCacheRoles(): Role[] {
    let roles = window.sessionStorage.getItem(StorageService.cacheRolesPath);

    return !roles ? [] : <Role[]>JSON.parse(roles);
  }

  /** removes cached roles in session storage */
  public removeCacheRoles() {
    window.sessionStorage.removeItem(StorageService.cacheRolesPath);
  }

  public setCacheSupervisors(supervisors: User[]){
    window.sessionStorage.setItem(StorageService.cacheSupervisorsPath, JSON.stringify(supervisors));
  }
  public getCacheSupervisors(): User[] {
    let supervisors = window.sessionStorage.getItem(StorageService.cacheSupervisorsPath);
    return !supervisors ? [] : <User[]>JSON.parse(supervisors);
  }
  public removeCacheSupervisors(){
    window.sessionStorage.removeItem(StorageService.cacheSupervisorsPath);
  }
}
