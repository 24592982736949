import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

//Material
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { LoginComponent } from './components/auth/login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpErrorInterceptor } from './services/http-error.interceptor';
import { AuthInterceptor } from './services/auth.interceptor';
import { SnackbarComponent } from './components/layout/snackbar/snackbar.component';
import { PasswordForgotComponent } from './components/auth/password-forgot/password-forgot.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PermissionGuard } from './guards/permission.guard';
import { ToolbarComponent } from './components/layout/toolbar/toolbar.component';
import { LayoutComponent } from './components/layout/layout/layout.component';
import { BadgeListComponent } from './components/badges/badge-list/badge-list.component';
import { BadgeFormComponent } from './components/modals/badge-form/badge-form.component';
import { PeopleListComponent } from './components/people/people-list/people-list.component';
import { PersonViewComponent } from './components/people/person-view/person-view.component';
import { PersonFormComponent } from './components/modals/person-form/person-form.component';
import { BadgeViewComponent } from './components/badges/badge-view/badge-view.component';
import { DeleteConfirmationComponent } from './components/modals/delete-confirmation/delete-confirmation.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { PdfExportListComponent } from './components/exports/pdf/pdf-export-list/pdf-export-list.component';
import { PDFExportFormComponent } from './components/modals/pdfexport-form/pdfexport-form.component';
import { RolesListComponent } from './components/roles/roles-list/roles-list.component';
import { MembersOfRoleComponent } from './components/roles/members-of-role/members-of-role.component';
import { EditRoleComponent } from './components/roles/edit-or-add-role/edit-or-add-role.component';
import { UserActionListComponent } from './components/useractions/user-action-list/user-action-list.component';
import { PasswordSetterComponent } from './components/password-setter/password-setter.component';
import { PasswordResetComponent } from './components/auth/password-reset/password-reset.component';
import { TimeEntryFormComponent } from './components/modals/time-entry-form/time-entry-form.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SnackbarComponent,
    PasswordForgotComponent,
    DashboardComponent,
    ToolbarComponent,
    LayoutComponent,
    BadgeListComponent,
    BadgeFormComponent,
    PeopleListComponent,
    PersonViewComponent,
    PersonFormComponent,
    BadgeViewComponent,
    DeleteConfirmationComponent,
    HasPermissionDirective,
    PdfExportListComponent,
    PDFExportFormComponent,
    RolesListComponent,
    MembersOfRoleComponent,
    EditRoleComponent,
    UserActionListComponent,
    PasswordSetterComponent,
    PasswordResetComponent,
    TimeEntryFormComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    //Material
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCardModule,
    MatDialogModule,
    MatChipsModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatMomentDateModule,
    
  ],
  providers: [
    PermissionGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }, {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'de-DE'},
  ],
    
  bootstrap: [AppComponent]
})
export class AppModule { }
