<app-layout>
    <div class="container">
        <h1>{{'user.page.title' | translate | titlecase}}</h1>
        <div class="button-sepperator">
            <!-- <button mat-flat-button disabled>{{'common.filter' | translate}} <mat-icon>filter_list</mat-icon></button> -->
            <div style="width:91px;"></div>
            <button mat-raised-button routerLink="./" *hasPermission="[WRITE_PEOPLE]"
                [queryParams]="{openDialog: true, peoplePageIndex: pageIndex, peoplePageSize: pageSize}">
                {{'create.user' | translate}}
            </button>
            <button mat-flat-button (click)="onRefresh()">
                <mat-icon>cached</mat-icon>
            </button>
        </div>
        <!-- <br> -->
        <!-- <div class="chip-container">
            <mat-chip-list>
                <mat-chip [removable]="true" (removed)="removeSearchFilter()">search: mat*
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div> -->
        <table mat-table [dataSource]="userDataSource" class="mytable dark-header" style="width:100%;margin-top:10px;">

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> {{'user.id' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- date Column -->
            <ng-container matColumnDef="firstname">
                <th mat-header-cell *matHeaderCellDef> {{'user.firstname' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
            </ng-container>


            <!-- start_time Column -->
            <ng-container matColumnDef="lastname">
                <th mat-header-cell *matHeaderCellDef> {{'user.lastname' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.lastname}} </td>
            </ng-container>

            <!-- end_time Column -->
            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef> {{'common.username' | translate}} </th>
                <td mat-cell *matCellDef="let element"> <a
                        [routerLink]="['/', 'people', element.id]">{{element.username}}</a></td>
            </ng-container>

            <!-- end_time Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{'common.actions' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="c-pointer" color="primary" routerLink="./"
                        [queryParams]="{openDialog: true, userId: element.id}" *hasPermission="[WRITE_PEOPLE]">{{'create.person' | translate}}
                    </mat-icon>
                    <div *ngIf="element.can_be_deleted" style="display: inline;">
                        <mat-icon class="c-pointer" color="warn" (click)="deleteUser(element.id)"
                            *hasPermission="[DELETE_PEOPLE]">delete</mat-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator showFirstLastButtons [pageIndex]="pageIndex" [length]="pageLength" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" [pageSizeOptions]="[10, 20, 50, 200]"
            (page)="setParameterFromTable($event)" #entryPaginator>
        </mat-paginator>
    </div>
</app-layout>