import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';
import { SnackbarService } from './snackbar.service';
import { saveAs } from 'file-saver';
import { PDFRolesExportRequest } from '../models/pdf-export';

@Injectable({
  providedIn: 'root'
})
export class ExportService extends BaseService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private snackbarService: SnackbarService,) {
    super();
  }

  public getPDFArchives(): Promise<string[]> {
    return this.http.get<string[]>(this.getBaseUrl() + 'exports/pdf/archives').toPromise();
  }

  public generateRolePDFArchive(exportRequest: PDFRolesExportRequest): Promise<string> {
    return this.http.post<string>(this.getBaseUrl() + 'exports/pdf/generate-roles-timetables', exportRequest).toPromise();
  }

  public downloadArchive(filename: string) {
    return this.http.post(this.getBaseUrl() + 'exports/pdf/download', { filename }, { responseType: 'blob' }).toPromise().then((response) => {
      let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      saveAs(blob, filename)
      window.URL.createObjectURL(blob);
    });
  }

  public deleteArchive(filename: string) {
    return this.http.delete(this.getBaseUrl() + 'exports/pdf/archives/'+filename).toPromise();
  }
}
