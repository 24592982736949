import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { PDFRolesExportRequest } from 'src/app/models/pdf-export';
import { ExportService } from 'src/app/services/export.service';
import { RoleService } from 'src/app/services/role.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { StorageService } from 'src/app/services/storage.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMMM YYYY', 
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-pdfexport-form',
  templateUrl: './pdfexport-form.component.html',
  styleUrls: ['./pdfexport-form.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class PDFExportFormComponent implements OnInit {

  exportForm;
  roles = [];
  selectedDate: string;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private snackbarService: SnackbarService,
    private roleService: RoleService,
    private storageService: StorageService,

    public dialogRef: MatDialogRef<PDFExportFormComponent>,
    public exportService: ExportService
  ) { }

  ngOnInit(): void {
    this.exportForm = this.fb.group({
      role_id: ['', [Validators.required]],
      date: ['', [Validators.required]]
    });

    this.roles = this.storageService.getCacheRoles();
    this.getRoles();
    
  }

  getRoles() {
    this.roleService.getRoles().then(roles => this.roles = roles);
  }

  onSubmit() {
    let formData = this.exportForm.getRawValue();

    // Das ausgewählte Datum auf das gewünschte Format "YYYY-MM" kürzen
    const selectedDate = formData['date'].substring(0, 7);
    let request: PDFRolesExportRequest = { role_id: formData['role_id'], date: selectedDate + '-01' };
    this.exportService.generateRolePDFArchive(request).then(
      archive => {
        this.dialogRef.close(archive);
      }
    );
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${year}-${month}`;
  }

  openDatePicker(datePicker) {
    datePicker.open();
  }

  closeDatePicker(eventData: any, datePicker?: any) {
    const selectedDate = eventData.toDate();
  
    // Setzen Sie das Datum auf den ersten Tag des ausgewählten Monats
    selectedDate.setDate(1);
  
    const formattedDate = this.formatDate(selectedDate);
  
    // Aktualisieren Sie das Form Control 'date' mit dem ausgewählten Datum im gewünschten Format
    this.exportForm.get('date').setValue(formattedDate);
  
    datePicker.close();
  }
 
  
}
