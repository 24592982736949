import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { PasswordForgotComponent } from './components/auth/password-forgot/password-forgot.component';
import { BadgeListComponent } from './components/badges/badge-list/badge-list.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PermissionGuard } from './guards/permission.guard';
import { PeopleListComponent } from './components/people/people-list/people-list.component';
import { PersonViewComponent } from './components/people/person-view/person-view.component';
import { PdfExportListComponent } from './components/exports/pdf/pdf-export-list/pdf-export-list.component';
import {RolesListComponent} from "./components/roles/roles-list/roles-list.component";
import {MembersOfRoleComponent} from "./components/roles/members-of-role/members-of-role.component";
import { EditRoleComponent } from './components/roles/edit-or-add-role/edit-or-add-role.component';
import { UserActionListComponent } from './components/useractions/user-action-list/user-action-list.component';
import { PasswordSetterComponent } from './components/password-setter/password-setter.component';
import { PasswordResetComponent } from './components/auth/password-reset/password-reset.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard' //for when the user is already logged in
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [PermissionGuard]
  },
  {
    path: 'badges',
    canActivate: [PermissionGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: BadgeListComponent
      },
    ]
  },
  {
    path: 'user-actions/:userId',
    canActivate: [PermissionGuard],
    component: UserActionListComponent
  },
  {
    path: 'people',
    canActivate: [PermissionGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: PeopleListComponent
      },
      {
        path: ':id',
        component: PersonViewComponent
      }
    ]
  },
  {
    path: 'exports',
    children: [
      {
        path: 'pdf',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: PdfExportListComponent
          },
        ]
      }
    ]
  },
  {
  path:'password-setter',
    component: PasswordSetterComponent
  },
  {
    path: 'roles',
    children: [
      {
        path: 'list',
        component: RolesListComponent,
      },
      {
        path: 'membersOfRole/:id',
        component: MembersOfRoleComponent,
        children: [
          {
            path: ':id',
            component: PersonViewComponent,
          }
        ]
      },
      {
        path: 'role/:id',
        component: EditRoleComponent,
      },
      {
        path: 'newRole',
        component: EditRoleComponent,
      }
    ]
  },
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'password-forgotten',
        component: PasswordForgotComponent
      },
      {
        path: 'password-reset/:token',
        component: PasswordResetComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
