import { formatDate } from '@angular/common';
import { Component, Inject, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TimeEntry } from 'src/app/models/timeentry';
import { User } from 'src/app/models/user';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { TimeEntryService } from 'src/app/services/time-entry.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-time-entry-form',
  templateUrl: './time-entry-form.component.html',
  styleUrls: ['./time-entry-form.component.scss']
})
export class TimeEntryFormComponent {
  timeEntryForm: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { user: User, timeEntry: TimeEntry}, private dialogRef: MatDialogRef<TimeEntryFormComponent>, private fb: FormBuilder, private timeEntryService: TimeEntryService, private userService: UserService, private snackbarService: SnackbarService) {

    if (this.data.timeEntry) {
      const startDateTime = new Date(this.data.timeEntry.start_time);
      const endDateTime = this.data.timeEntry.end_time ? new Date(this.data.timeEntry.end_time) : null;
      this.timeEntryForm = this.fb.group({
        date: [startDateTime, Validators.required],
        startTime: [this.formatTime(startDateTime), Validators.required],
        endTime: [endDateTime ? this.formatTime(endDateTime) : null, Validators.required]
      });
    } else {
      this.timeEntryForm = this.fb.group({
        date: ['', Validators.required],
        startTime: ['', Validators.required],
        endTime: ['']
      });
    }
    
  }

  openDatePicker(datePicker: any) {
    datePicker.open();
  }


  onSubmit() {
    if (this.timeEntryForm.valid) {
      const date = this.timeEntryForm.value.date; // Datum
      const startTime = this.timeEntryForm.value.startTime; // Startzeit
      const endTime = this.timeEntryForm.value.endTime; // Endzeit

      if (!this.data.user || !this.data.user.badges || this.data.user.badges.length === 0) {
        this.snackbarService.openSnackBar({ text: 'Dieser Benutzer hat keinen Badge', color: 'warn' });
        return;
      }

      const badge_id = this.data.user.badges[0].id;

      // Formatieren Sie das Datum und die Uhrzeit entsprechend Ihrem Datenbankformat
      const formattedDate = formatDate(date, 'yyyy-MM-dd', 'en-US');
      const formattedStartTime = startTime + ':00'; // Fügen Sie ':00' für die Sekunden hinzu

      let formattedEndTime = null; // Setzen Sie standardmäßig end_time auf null
     
      if (endTime) {
        formattedEndTime = endTime + ':00'; // Fügen Sie ':00' für die Sekunden hinzu
      }
      
      // Kombinieren Sie Datum und Uhrzeit in einem String
      const start_time = `${formattedDate} ${formattedStartTime}`;
      const end_time = formattedEndTime ? `${formattedDate} ${formattedEndTime}` : null;
  
      // Überprüfen Sie, ob die Startzeit kleiner als die Endzeit ist
      if (end_time && new Date(start_time) >= new Date(end_time)) {
        // Wenn die Bedingung nicht erfüllt ist, geben Sie eine Fehlermeldung aus und verlassen Sie die Funktion
        this.snackbarService.openSnackBar({ text: 'Die Startzeit darf nicht größer oder gleich der Endzeit sein', color: 'warn' });
        return;
      }
  
      // Erstellen Sie ein Objekt für die Aktualisierung oder Erstellung
      let entryData;
      if (this.data.timeEntry) {
        // Aktualisieren
        entryData = {  start_time, end_time};
        this.timeEntryService.updateTimeEntry(this.data.timeEntry.id, entryData).then(
          (timeentry) => {
            this.snackbarService.openSnackBar({ text: 'Zeitstempel erfolgreich aktualisiert', color: 'success' });
            this.dialogRef.close(true);
          },
          (error) => {
            this.snackbarService.openSnackBar({ text: 'Etwas ist schief gelaufen', color: 'warn' });
            this.dialogRef.close(null);
          }
        );
      } else {
        // Erstellen
        entryData = { user_id: this.data.user.id, start_time, end_time, badge_id, time: 10 };
        this.timeEntryService.createTimeEntry(entryData).then(
          (timeentry) => {
            this.snackbarService.openSnackBar({ text: 'Neuer Zeitstempel eingefügt', color: 'success' });
            this.dialogRef.close(true);
          },
          (error) => {
            this.snackbarService.openSnackBar({ text: 'Etwas ist schief gelaufen', color: 'warn' });
            this.dialogRef.close(null);
          }
        );
      }
    }
  }
  onCancel() {
    this.dialogRef.close(null);
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    return `${year}-${month}`;
  }
 // Methode zur Formatierung der Zeit
formatTime(time: Date): string {
  // Zeitzonenoffset in Minuten
  const timezoneOffset =+ 0; 

  // Zeit mit Zeitzonenoffset anpassen
  const localTime = new Date(time.getTime() + timezoneOffset * 60 * 1000);
  console.log(localTime)
  const hours = localTime.getUTCHours().toString().padStart(2, '0');
  console.log(hours)
  const minutes = localTime.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}
 
}
