import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RoleService } from '../../../services/role.service';
import { Role } from '../../../models/role';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { FilterService } from 'src/app/services/filter.service';
import { DeleteConfirmationComponent } from '../../modals/delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { Permissions } from 'src/app/permissions';
import { Subscription } from 'rxjs';
import { Filter } from 'src/app/models/filter';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss'],
})
export class RolesListComponent implements OnInit {
  rawRoles: Role[] = [];
  roles: MatTableDataSource<Role>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  WRITE_ROLES = Permissions.WRITE_ROLES;

  // filter
  activeFilter: Filter = { text: '', roles: [], supervisors: [] };
  private subscribedFilter: Subscription = null;

  displayedColumnsRoles = ['name', 'description', 'amountOfMembers', 'actions'];

  constructor(
    private roleService: RoleService,
    private router: Router,
    private filterService: FilterService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.roleService.getRoles().then((response) => {
      this.subscribedFilter = this.filterService
        .getFilterObservable()
        .subscribe((filter) => {
          this.activeFilter = filter;
          this.rawRoles = this.filterService.filterRoles(response, filter);
          this.roles = new MatTableDataSource(this.rawRoles);
          this.roles.paginator = this.paginator;
          this.roles.data = this.roles.data.sort((a, b) =>
            a.name > b.name ? 1 : -1
          );
        });
    });
  }

  deleteRole(role: Role): void {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
      data: { text: 'messages.delete.confirmation', textData: '' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.roleService.deleteRole(role.id);
        this.rawRoles = [...this.rawRoles.slice(0, this.rawRoles.indexOf(role)), ...this.rawRoles.slice(this.rawRoles.indexOf(role) + 1)];
        this.roles.data = this.rawRoles;
      }
    });
  }
}
