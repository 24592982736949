<app-layout>
    <div class="container">
        <h1>{{'menu.badges' | translate}}</h1>
        <div class="button-sepperator">
            <!-- <button mat-flat-button disabled>{{ 'common.filter' | translate }} <mat-icon>filter_list</mat-icon></button> -->
            <div style="width:91px;"></div>
            <button mat-raised-button routerLink="./"
                [queryParams]="{openDialog: true, badgePageIndex: pageIndex, BadgePageSize: pageSize}"
                *hasPermission="[WRITE_BADGES]">{{'create.badge' | translate}}</button>
            <button mat-flat-button (click)="onRefresh()">
                <mat-icon>cached</mat-icon>
            </button>
        </div>
        <table mat-table [dataSource]="dataSource" class="mytable" style="width:100%;margin-top:10px">

            <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef> {{'common.id' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{'badge.name' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- RFID Column -->
            <ng-container matColumnDef="rfid">
                <th mat-header-cell *matHeaderCellDef> {{'badge.rfid' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.rfid}} </td>
            </ng-container>

            <!-- user Column -->
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef> {{'badge.user' | translate}} </th>
                <td mat-cell *matCellDef="let element"> <a *ngIf="element.user"
                        [routerLink]="['/', 'people', element.user.id]">{{element.user.username}}</a></td>
            </ng-container>

            <!-- actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                    {{'common.actions' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <span>
                        <mat-icon class="c-pointer" color="primary" routerLink="./"
                            [queryParams]="{openDialog: true, badgeId:element.id}" *hasPermission="[WRITE_BADGES]">
                            create</mat-icon>
                        <mat-icon class="c-pointer" color="warn" routerLink="./" (click)="deleteBadge(element.id)"
                            *hasPermission="[DELETE_BADGES]">
                            delete</mat-icon>
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator showFirstLastButtons [pageIndex]="pageIndex" [length]="pageLength" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="setParameterFromTable($event)"></mat-paginator>

    </div>
</app-layout>