import { Component, OnInit } from '@angular/core';
import { Permissions } from 'src/app/permissions';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  READ_BADGES = Permissions.READ_BADGES;
  GENERATE_PDF = Permissions.GENERATE_PDF;
  WRITE_ROLES = Permissions.WRITE_ROLES;
  READ_USER_ACTIONS = Permissions.READ_USER_ACTIONS;
  SET_USER_PASSWORDS = Permissions.SET_USER_PASSWORDS;

  user = null;
  userId = 0;
  
  constructor(
    private authService: AuthService,
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.user = this.storageService.getUser();
  }

  onLogout() { 
    this.authService.logout();
  }
}
