import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Role } from 'src/app/models/role';
import { RoleService } from 'src/app/services/role.service';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Permission } from 'src/app/models/permission';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-edit-role',
  templateUrl: './edit-or-add-role.component.html',
  styleUrls: ['./edit-or-add-role.component.scss'],
})
export class EditRoleComponent implements OnInit {
  role: Role;
  allExistingPermissions: Permission[];
  powerList: [];
  form: UntypedFormGroup = this.formBuilder.group({
    name: '',
    description: '',
    permissions: new UntypedFormArray([]),
    power: NaN,
  });

  get permissionsFormArray() {
    return this.form.controls.permissions as UntypedFormArray;
  }

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private formBuilder: UntypedFormBuilder,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    //Hier wird überprüft ob es um eine neue Rolle oder eine Bearbeitung geht
    if (this.router.url === '/roles/newRole') {
      this.roleService.getPermissions().subscribe((response) => {
        this.allExistingPermissions = response;
        this.allExistingPermissions.forEach((e) =>
          this.permissionsFormArray.push(new UntypedFormControl(false))
        );
      });
    } else {
      this.route.params.subscribe((params) =>
        this.roleService.getRole(params.id).then((response) => {
          this.role = response;
          this.form.patchValue({
            name: this.role.name,
            description: this.role.description,
            power: this.role.power,
          });
          // Nachdem die Rolle geladen wurde, holen Sie die Berechtigungen und initialisieren Sie das Formular
          this.roleService.getPermissions().subscribe((permissions) => {
            this.allExistingPermissions = permissions;
            this.initForm();
          });
        })
      );

    }
  }

  onSubmit(): void {
    const result = this.form.value;
    const permissionsForRequest = [];

    result.permissions.forEach((e, i) => {
      if (e) {
        permissionsForRequest.push(this.allExistingPermissions[i].id);
      }
    });
    result.permissions = permissionsForRequest;
    if (this.router.url === '/roles/newRole') {
      this.roleService
        .createRole(result)
        .then(() => {
          this.snackbarService.openSnackBar({
            text: 'roles.message.saved',
            color: 'success',
          });
          this.router.navigateByUrl('roles/list');
        })
        .catch((error) => {
          switch (error.status) {
            case 400:
              this.snackbarService.openSnackBar({
                text: '[T]bad request',
                color: 'warn',
              });
              break;
            case 444:
              this.snackbarService.openSnackBar({
                text: 'duplicate roles',
                color: 'warn',
              });
              break;
              case 500:
              this.snackbarService.openSnackBar({
                text: 'something went horribly wrong',
                color: 'warn',
              });
            default:
              break;
          }
        });
    } else {
      this.roleService
        .putRole(this.role.id, result)
        .then(() => {
          this.snackbarService.openSnackBar({
            text: 'roles.message.saved',
            color: 'success',
          });
          this.router.navigateByUrl('roles/list');
        })
        .catch((error) => {
          switch (error.status) {
            case 400:
              this.snackbarService.openSnackBar({
                text: 'bad request',
                color: 'warn',
              });
              break;
            case 444:
              this.snackbarService.openSnackBar({
                text: 'duplicate roles',
                color: 'warn',
              });
              break;
              case 500:
              this.snackbarService.openSnackBar({
                text: 'something went horribly wrong',
                color: 'warn',
              });
            default:
              break;
          }
        });
    }
  }
  // Diese Methode wird aufgerufen, um das Formular zu initialisieren
  private initForm() {
    if (this.allExistingPermissions && this.allExistingPermissions.length > 0) {
      this.allExistingPermissions.forEach((permission) => {
        const control = new UntypedFormControl(false);
        
        if (this.role && this.role.permissions) {
          const hasPermission = this.role.permissions.some((e2) => e2.name === permission.name);
          control.setValue(hasPermission);
        }

        this.permissionsFormArray.push(control);
      });
    }
  }
}
