import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BadgeFormComponent } from 'src/app/components/modals/badge-form/badge-form.component';
import { Badge, BadgeResponse } from 'src/app/models/badge';
import { User } from 'src/app/models/user';
import { BadgeService } from 'src/app/services/badge.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { Permissions } from 'src/app/permissions';
import { StorageService } from 'src/app/services/storage.service';
import { DeleteConfirmationComponent } from 'src/app/components/modals/delete-confirmation/delete-confirmation.component';
import { Title } from '@angular/platform-browser';
import { FilterService } from 'src/app/services/filter.service';
import { Filter } from 'src/app/models/filter';
import { RoleService } from 'src/app/services/role.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss']
})
export class BadgeListComponent implements OnInit {

  //permissions
  WRITE_BADGES = Permissions.WRITE_BADGES;
  DELETE_BADGES = Permissions.DELETE_BADGES;
  
  activeFilter: Filter = { text: '', roles: [], supervisors: [] };

  private subscribedFilter: Subscription = null;
  private currentUser: User;
  public users: User[] = [];
  public rawUsers: User[] = [];
  constructor(
    private badgeService: BadgeService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private storageService: StorageService,
    private titleService: Title,
    private filterService: FilterService,
    private roleService: RoleService,
    private authService: AuthService
  ) { }


  //pagination
  pageLength = 0;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions = [10, 20, 50, 100, 200];


  pageEvent: PageEvent;

  @ViewChild(MatPaginator) badgePaginator: MatPaginator;
  dataSource: MatTableDataSource<Badge> = new MatTableDataSource<Badge>();
  badges: Badge[] = [];
  filteredBadges: Badge[] = [];
  

  routeQueryParams$: Subscription;
  routeQueryParams = {};

  displayedColumns: string[] = [
    // 'id',
    'name',
    'rfid',
    'user',
    'actions'
  ];

  setParameterFromTable(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.pageLength = pageEvent.length;
    this.pageSize = pageEvent.pageSize;

    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { badgePageIndex: this.pageIndex, badgePageSize: this.pageSize } });
  }

  setTableFromParameter() {
    this.badgePaginator.pageIndex = this.pageIndex;
    this.badgePaginator.pageSize = this.pageSize;
  }

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.subscribedFilter = this.filterService.getFilterObservable().subscribe(filter => {
      this.activeFilter = filter;
      this.filterBadgesTable();
    });

    this.titleService.setTitle('Chronos | Badges');
    this.badges = this.storageService.getCacheBadges();
    this.filterBadgesTable();

    //should the edit form open?
    this.routeQueryParams$ = this.activatedRoute.queryParams.subscribe((params) => {
      this.routeQueryParams = params;

      if (params.openDialog) {
        if (params.badgeId) { //edit badge
          this.badgeService.getBadgeById(params.badgeId).then(badge => {
            this.openDialog(badge);
          });
        } else { //create badge
          this.openDialog();
        }
      }

      if (params.badgePageIndex) {
        this.pageIndex = params['badgePageIndex'];
      }
      if (params['badgePageSize']) {
        this.pageSize = params['badgePageSize'];
      }

    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.badgePaginator;
    console.log(this.currentUser);
    if (this.currentUser) {
      this.getUsersAndBadgesForCurrentUser(false);
    }
  }


  onRefresh() {
    if (this.currentUser) {
      this.getUsersAndBadgesForCurrentUser(true);
    }
    
  }

  async getUsersAndBadgesForCurrentUser(refresh = false) {
    try {
      // Benutzerdaten abrufen
      const users = await this.userService.getUsers();
      
      // Benutzer filtern, die die Bedingung erfüllen (weniger Rolepower als der aktuelle Benutzer oder der aktuelle Benutzer selbst)
      this.rawUsers = users; // Alle Benutzer für spätere Verwendung speichern
      const currentUserRolePower = this.roleService.getRolePower(this.currentUser);
      
      const filteredUsers = users.filter(user => {
        const userRolePower = this.roleService.getRolePower(user);
        const isCurrentUser = user.id === this.currentUser.id;
        return userRolePower < currentUserRolePower || isCurrentUser;
      });
  
      // Gefilterte Benutzerdaten im Cache speichern und verarbeiten
      this.storageService.setCacheUsers(filteredUsers);
  
      // Badges abrufen
      const badges = await this.badgeService.getBadges();
      
      // Badges filtern, die zu Benutzern mit weniger Rolepower gehören oder dem aktuellen Benutzer selbst
      this.badges = (<Badge[]>badges).filter(badge => {
        const badgeUser = this.rawUsers.find(user => user.id === badge.user_id);
        if (!badgeUser) {
          return false; // Benutzer nicht gefunden
        }
        const userRolePower = this.roleService.getRolePower(badgeUser);
        const isCurrentUser = badgeUser.id === this.currentUser.id;
        return userRolePower < currentUserRolePower || isCurrentUser;
      });
  
      // Badges sortieren
      this.badges = this.badges.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
  
      // Tabellenparameter und Badgetabelle einstellen
      this.setTableFromParameter();
      this.filterBadgesTable();
      
      // Gefilterte Badges im Cache speichern
      this.storageService.setCacheBadges(this.badges);
  
      if (refresh) {
        this.snackbarService.openSnackBar({ text: 'messages.reloaded.data', color: 'success' });
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Benutzerdaten:', error);
      // Führen Sie hier die gewünschte Fehlerbehandlung durch
    }
  }
  
  
  private filterBadgesTable() {
    this.filteredBadges = this.filterService.filterBadges(this.badges, this.activeFilter);

    this.dataSource.data = this.filteredBadges;
  }

  public openDialog(badge = null) {
    const dialogRef = this.dialog.open(BadgeFormComponent, {
      width: '400px',
      data: badge
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { badgePageIndex: this.pageIndex, badgePageSize: this.pageSize } });
      //update badges
      if (!!result) {
        this.updateBadges(result);
      }
    });
  }

  updateBadges(badge: Badge) {
    let found = this.badges.find((b, index) => {
      if (b.id == badge.id) {
        this.badges[index] = badge;
        return b;
      }
    });

    if (!found) {
      this.badges.push(badge);
      this.badges = this.badges.sort((a, b) => {
        return a.id > b.id ? 1 : -1
      });
    }

    this.filterBadgesTable();
  }

  ngOnDestroy() {
    this.routeQueryParams$.unsubscribe();
    this.subscribedFilter.unsubscribe();
  }

  deleteBadge(id: number) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
      data: { text: 'messages.delete.confirmation', textData: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.badgeService.deleteBadge(id).then(
          () => {
            this.badges = this.badges.filter((badge) => {
              if (badge.id != id) return badge;
            });
            this.filterBadgesTable();
            this.snackbarService.openSnackBar({ text: 'badge.message.deleted', color: 'success' })
          }).catch((error) => {
            switch (error.status) {
              case 403:
                //no permission
                this.snackbarService.openSnackBar({ text: 'error.authentication.permission', color: 'error' });
                break;

              default:
                break;
            }
          });
      }
    });
  }

}
