import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserAction } from '../models/useraction';
import { BaseService } from './base.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class UserActionService extends BaseService {
  constructor(private http: HttpClient, private snackbarService: SnackbarService) {
    super();
  }

  public getUserActions(): Promise<UserAction[]> {
    return this.http.get<UserAction[]>(this.getBaseUrl() + 'user-actions').toPromise().catch(this.handleHttpError);
  }

  public getUserActionsForUser(id:number): Promise<UserAction>{
    return this.http.get<UserAction>(this.getBaseUrl() + `getUserActionsForUser/${id}`).toPromise().catch(this.handleHttpError);
  }

  private handleHttpError = (error) => {
    switch (error.status) {
      case 403:
        this.snackbarService.openSnackBar({ text: 'errors.authentication.permission', color: 'warn' });
        break;
      case 400:
        this.snackbarService.openSnackBar({ text: 'errors.badrequest', color: 'warn' });
        break;
      case 422:
        this.snackbarService.openSnackBar({ text: '[T]user.message.error.form error stuff', color: 'warn' });
        break;
      case 500:
        this.snackbarService.openSnackBar({ text: 'errors.server.500', color: 'warn' });
        break;

      default:
        break;
    }
    return null;
  }
}
