import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Badge } from 'src/app/models/badge';
import { Role } from 'src/app/models/role';
import { User, UserRequest } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { BadgeService } from 'src/app/services/badge.service';
import { RoleService } from 'src/app/services/role.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { BadgeFormComponent } from '../badge-form/badge-form.component';

@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss']
})
export class PersonFormComponent implements OnInit {

  personForm: UntypedFormGroup;
  badges: Badge[] = [];
  roles: Role[] = [];
  users: User[] = [];

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private badgeService: BadgeService,
    private roleService: RoleService,
    private storageService: StorageService,
    public dialogRef: MatDialogRef<BadgeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User) { }

  ngOnInit(): void {
    this.personForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)]],
      lastname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)]],
      username: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)]],
      email: [],
      supervisor_id: 0,
      roles: [],
      badges: [],
      // is_hidden: [],
      can_login: [],
      is_supervisor: [],
    });

    if (!!this.data) {
      this.setData(this.data);
    } else {
      this.setData(<User>{
        firstname: '',
        lastname: '',
        username: '',
        roles: [],
        badges: [],
        is_hidden: false,
        can_login: false,
        is_supervisor: false,
        email: '',
      });
    }

    this.badges = this.storageService.getCacheBadges();
    this.roles = this.storageService.getCacheRoles();
    this.users = this.storageService.getCacheSupervisors().sort((a, b) => a.username > b.username ? 1 : -1);

    this.getBadges();
    this.getRoles();
    this.getUsers();
  }

  getUsers() {
    this.userService.getSupervisors().then(users => { 
      this.users = <User[]>users.sort((a, b) => a.username > b.username ? 1 : -1); 
      this.storageService.setCacheSupervisors(users);
    });
  }

  getRoles() {
    this.roleService.getRoles().then(roles => {
      this.roles = <Role[]>roles;
    })
  }

  getBadges() {
    this.badgeService.getBadges().then(badges => {
      this.badges = (<Badge[]>badges).filter((badge) => {
        if ((!!this.data && badge.user_id == this.data.id) || badge.user_id == 0 || badge.user_id == null) {
          return badge;
        }
      });
    })
  }

  setData(data) {
    this.personForm.patchValue({
      firstname: data.firstname,
      lastname: data.lastname,
      username: data.username,
      roles: data.roles.map(r => r.id),
      badges: data.badges.map(b => b.id),
      supervisor_id: data.supervisor_id,
      // is_hidden: data.hidden,
      is_supervisor: data.is_supervisor,
      can_login: data.can_login,
      email: data.email,
    });
  }

  onSubmit() {
    let formData = this.personForm.getRawValue();
    console.log(formData)
    let request: UserRequest = { firstname: formData['firstname'], lastname: formData['lastname'], username: formData['username'], is_hidden: false, can_login: formData['can_login'], roles: formData['roles'], badges: formData['badges'], supervisor_id: formData['supervisor_id'], is_supervisor: !!formData['is_supervisor'], email: formData['email'] };
    console.log(request);
    if (!!this.data) {
      console.log(this.data);
      this.userService.updateUser(this.data.id, request).then(user => {
        console.log(user);
        if (!!user) {
          this.snackbarService.openSnackBar({ text: 'user.message.saved', color: 'success' });
          this.dialogRef.close(user);
        }
      }).catch(error => { this.httpErrorHandler });
    } else {
      this.userService.createUser(request).then(user => {
        if (!!user) {
          this.snackbarService.openSnackBar({ text: 'user.message.saved', color: 'success' });
          this.dialogRef.close(user);
        }
      }).catch(error => { this.httpErrorHandler });
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }

  httpErrorHandler = (error) => {
    switch (error.status) {
      default:
        console.log(error)
        break;
    }
  }

}
