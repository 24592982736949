import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Badge } from '../models/badge';
import { Filter } from '../models/filter';
import { Role } from '../models/role';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private _filter = [];
  static filter: BehaviorSubject<any> = new BehaviorSubject([]);

  public getFilterObservable(): Observable<any> {
    return FilterService.filter.asObservable();
  }

  public getFilter(): any[] {
    return this._filter;
  }

  public setFilter(items = []) {
    this._filter = items;
    FilterService.filter.next(this._filter);
  }

  public clearFilter() {
    this._filter = [];
    FilterService.filter.next(this._filter);
  }

  public addFilterItem(item) {
    this._filter.push(item);
    FilterService.filter.next(this._filter);
  }

  constructor() { }

  public filterUsers(users: User[] = [], filter: Filter): User[] {
    return users.filter(u => {

      let hasRole = !!filter.roles && filter.roles.length > 0 ? false : true;

      if (!hasRole) {
        u.roles.forEach(r => {
          if (filter.roles.includes(r.id)) hasRole = true;
        });
      }

      let inName = !!filter.text && filter.text.length > 0 ?
        (u.firstname.toLowerCase().includes(filter.text.toLowerCase()) ||
          u.lastname.toLowerCase().includes(filter.text.toLowerCase()) ||
          u.username.toLowerCase().includes(filter.text.toLowerCase())) : true;
      let hasSupervisor = !!filter.supervisors && filter.supervisors.length > 0 ? (filter.supervisors.includes(u.supervisor_id) || filter.supervisors.includes(u.id) ): true;

      return hasRole && inName && hasSupervisor;
    });
  }

  //filters badges by name
  public filterBadges(badges: Badge[] = [], filter: Filter): Badge[] {

    if (!!filter.text && filter.text.length > 0) {
      badges = badges.filter(badge => {
        return badge.name.toLowerCase().includes(filter.text.toLowerCase()) || badge.rfid.toLowerCase().includes(filter.text.toLowerCase());
      });

      return badges;
    } else {
      return badges;
    }
  }

  // filter roles by name
  public filterRoles(roles: Role[], filter: Filter) {
    if (!!filter.text && filter.text.length > 0) {
      roles = roles.filter(role => {
        return role.name.toLowerCase().includes(filter.text.toLowerCase()) || role.description.toLowerCase().includes(filter.text.toLowerCase());
      });

      return roles;
    } else {
      return roles;
    }
  }
}
