import { Injectable } from '@angular/core';
import { TimeEntry, TimeEntryRequest } from '../models/timeentry';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class TimeEntryService extends BaseService{

  constructor(private http: HttpClient, private snackbarService: SnackbarService) { 
    super()
  }

  createTimeEntry(data: TimeEntryRequest){
    return this.http.post<TimeEntry>(this.getBaseUrl() + 'timeentries/createTimeEntry', data).toPromise().catch(this.handleHttpError)
  }
  updateTimeEntry(id:Number, data: string){
    
    return this.http.put<TimeEntry>(this.getBaseUrl() + `timeentries/editTimeEntry/${id}`, data).toPromise().catch(this.handleHttpError)
  }

  private handleHttpError = (error) => {
    switch (error.status) {
      case 403:
        this.snackbarService.openSnackBar({ text: 'errors.authentication.permission', color: 'warn' });
        break;
      case 400:
        this.snackbarService.openSnackBar({ text: 'errors.badrequest', color: 'warn' });
        break;
      case 422:
        this.snackbarService.openSnackBar({ text: '[T]user.message.error.form error stuff', color: 'warn' });
        break;
      case 500:
        this.snackbarService.openSnackBar({ text: 'errors.server.500', color: 'warn' });
        break;
      
      default:
        break;
    }
    return null;
  }
}
