import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { BadgeService } from 'src/app/services/badge.service';
import { RoleService } from 'src/app/services/role.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { BadgeFormComponent } from '../badge-form/badge-form.component';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<BadgeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { text, textData }) {

  }

  ngOnInit(): void {
  }

  onDelete() {
    this.dialogRef.close(true);
  }

  onKeep() {
    this.dialogRef.close(false);
  }

}
