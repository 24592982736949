<app-layout>
    <div class="container">
        <h1>{{'exports.pdf.myexports' | translate}}</h1>
        <div class="button-wrapper">
            <div style="width:91px"></div>
            <button mat-raised-button routerLink="./" [queryParams]="{openDialog: true}"
                *hasPermission="[CREATE_EXPORT]">{{'create.export' | translate}}</button>
            <button mat-flat-button (click)="onRefresh()">
                <mat-icon>cached</mat-icon>
            </button>
        </div>
        <table mat-table [dataSource]="dataSource" class="mytable" style="width:100%;margin-top:10px">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- ID Column -->
            <ng-container matColumnDef="creation_date">
                <th mat-header-cell *matHeaderCellDef> {{'exports.creation_date' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element[0]}} {{element[1].replace('-', ':')}}</td>
            </ng-container>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> {{'exports.type' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element[3]}} </td>
            </ng-container>

            <ng-container matColumnDef="export_date">
                <th mat-header-cell *matHeaderCellDef> {{'exports.export_date' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element[4]}} </td>
            </ng-container>


            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> {{'common.actions' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon class="c-pointer" (click)="startDownload(element.join('_')+'.zip')">get_app
                    </mat-icon>
                    <mat-icon color="warn" class="c-pointer" (click)="onDeleteArchive(element.join('_')+'.zip')">delete
                    </mat-icon>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator showFirstLastButtons [pageIndex]="pageIndex" [length]="pageLength" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" (page)="setParameterFromTable($event)"></mat-paginator>

    </div>
</app-layout>