export enum Permissions {
  READ_BADGES = 'READ_BADGES',
  WRITE_BADGES = 'WRITE_BADGES',
  DELETE_BADGES = 'DELETE_BADGES',

  READ_PEOPLE = 'READ_PEOPLE',
  WRITE_PEOPLE = 'WRITE_PEOPLE',
  DELETE_PEOPLE = 'DELETE_PEOPLE',

  READ_TIMES = 'READ_TIMES',

  READ_ROLES = 'READ_ROLES',
  WRITE_ROLES = 'WRITE_ROLES',
  DELETE_ROLES = 'DELETE_ROLES',

  GENERATE_PDF = 'GENERATE_PDF',
  CREATE_EXPORT = 'CREATE_EXPORT',

  READ_USER_ACTIONS = 'READ_USER_ACTIONS',
  SET_USER_PASSWORDS = 'SET_USER_PASSWORDS'
}
