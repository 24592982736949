import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarComponent } from '../components/layout/snackbar/snackbar.component';
import { SnackbarService } from '../services/snackbar.service';
import { StorageService } from '../services/storage.service';

@Injectable()
export class PermissionGuard  {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private snackbarService: SnackbarService) {

  }

  canActivate(data) {
    let token = this.storageService.getAuthorizationToken();
    let isLoggedIn = !!token && JSON.parse(atob(token.split('.')[1])).exp >= (new Date().getTime() / 1000);
    if (!isLoggedIn) {
      this.snackbarService.openSnackBar({
        text: 'errors.authentication.required',
        color: 'warn'
      });
      this.router.navigateByUrl('/auth/login')
    }

    return isLoggedIn;
  }
}