import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {

  passwordForgotForm = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    username: ['', [Validators.required, Validators.minLength(2)]]
  });

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

  onSend() { 
    this.authService.passwordForgotten(this.passwordForgotForm.getRawValue());


  }

}
