import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { UserAction } from 'src/app/models/useraction';
import { UserActionService } from 'src/app/services/user-action.service';

@Component({
  selector: 'app-user-action-list',
  templateUrl: './user-action-list.component.html',
  styleUrls: ['./user-action-list.component.scss']
})
export class UserActionListComponent implements OnInit {

  userActions:any;
  userId: number;
  dataSource: MatTableDataSource<UserAction>;
  displayedColumns: string[] = ['userId', 'path', 'method', 'data', 'createdAt'];
  constructor(private userActionService: UserActionService, private route: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.userId = +params['userId']; // Benutzer-ID aus dem Routenparameter abrufen
      this.getUserActions(); // Benutzeraktionen für die Benutzer-ID abrufen
    });
  }

  getUserActions(): void {
    this.userActionService.getUserActionsForUser(this.userId).then(actions => {

      this.userActions = actions;
      this.dataSource = this.userActions;
    });
  }

}
