<form [(formGroup)]="timeEntryForm" class="time-entry-form">
    <!-- Benutzername -->
    <h1>{{(!!data.timeEntry? 'timeentry.modal.title.edit' : 'timeentry.modal.title.new') | translate}} für {{ data.user.username }}</h1>
    
    <!-- Datum -->
    <mat-form-field appearance="outline">
        <mat-label>{{'common.date' | translate}}</mat-label>
        <input matInput [matDatepicker]="datePicker" formControlName="date" (click)="openDatePicker(datePicker)" />
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker startView="year"></mat-datepicker>
    </mat-form-field>
    
    <!-- Startzeit -->
    <mat-form-field appearance="outline">
        <mat-label>{{'timeentry.start_time' | translate}}</mat-label>
        <input matInput formControlName="startTime" type="time" />
        
    </mat-form-field>
    
    <!-- Endzeit -->
    <mat-form-field appearance="outline">
        <mat-label>{{'timeentry.end_time' | translate}}</mat-label>
        <input matInput type="time" formControlName="endTime" />
    </mat-form-field>
    
    <!-- Buttons -->
    <div class="button-wrapper">
        <button mat-flat-button color="primary" type="submit" tabindex="4" (click)="onSubmit()">{{ 'common.submit' | translate }}</button>
        <button mat-button type="button" color="warn" tabindex="5" (click)="onCancel()">{{ 'common.close' | translate }}</button>
    </div>
</form>