import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DeleteConfirmationComponent } from 'src/app/components/modals/delete-confirmation/delete-confirmation.component';
import { PDFExportFormComponent } from 'src/app/components/modals/pdfexport-form/pdfexport-form.component';
import { Badge } from 'src/app/models/badge';
import { User } from 'src/app/models/user';
import { Permissions } from 'src/app/permissions';
import { ExportService } from 'src/app/services/export.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-pdf-export-list',
  templateUrl: './pdf-export-list.component.html',
  styleUrls: ['./pdf-export-list.component.scss']
})
export class PdfExportListComponent implements OnInit, AfterViewInit {

  archives = [];
  CREATE_EXPORT = Permissions.CREATE_EXPORT

  //pagination
  pageLength = 0;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions = [10, 20, 50, 100, 200];


  pageEvent: PageEvent;

  @ViewChild(MatPaginator) badgePaginator: MatPaginator;
  dataSource: MatTableDataSource<Array<String>> = new MatTableDataSource<Array<String>>();
  badges: Badge[] = [];
  filteredBadges: Badge[] = [];
  users: User[] = [];

  routeQueryParams$: Subscription;
  routeQueryParams = {};

  displayedColumns: string[] = [
    'creation_date',
    'export_date',
    'type',
    'actions',
  ];


  constructor(
    private exportService: ExportService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private titleService: Title,
    private snackbarService: SnackbarService,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle('Chronos | Export PDF');

    //should the edit form open?
    this.routeQueryParams$ = this.activatedRoute.queryParams.subscribe((params) => {
      if (params['openDialog']) {
        this.openDialog();
      }

      if (params.exportPageIndex) {
        this.pageIndex = params.exportPageIndex;
      }
      if (params.exportPageSize) {
        this.pageSize = params.exportPageSize;
      }

    });

    this.getPDFArchives();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.badgePaginator;
  }

  public onRefresh() {
    this.getPDFArchives(true);
  }

  setParameterFromTable(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.pageLength = pageEvent.length;
    this.pageSize = pageEvent.pageSize;

    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { exportPageIndex: this.pageIndex, exportPageSize: this.pageSize } });
  }

  setTableFromParameter() {
    this.badgePaginator.pageIndex = this.pageIndex;
    this.badgePaginator.pageSize = this.pageSize;
  }

  getPDFArchives(refresh = false) {
    this.exportService.getPDFArchives().then(archives => {
      this.archives = archives.map(a => {
        if (refresh) {
          this.snackbarService.openSnackBar({ text: 'messages.reloaded.data', color: 'success' })
        }
        let splitted = a.split('/');
        return splitted[(splitted.length - 1)];
      }).sort((a, b) => a > b ? -1 : 1);


      this.dataSource.data = this.archives.map(a => {
        return a.replace('.zip', '').split('_')

      });

    });
  }

  startDownload(filename: string) {
    this.exportService.downloadArchive(filename).then();
  }

  deleteArchive(filename: string) {
    this.exportService.deleteArchive(filename).then((result) => {
      this.archives = this.archives.filter(a => a != filename);

      this.dataSource.data = this.archives.map(a => {
        return a.replace('.zip', '').split('_')
      });
      this.snackbarService.openSnackBar({ text: 'exports.messages.deleted', color: 'success' })
    });
  }

  onDeleteArchive(filename: string) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
      data: { text: 'messages.delete.confirmation', textData: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteArchive(filename);
      } else {
        return;
      }
    });
  }

  openDialog() {
    const dialogRef = this.dialog.open(PDFExportFormComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.router.navigate(['.'], { relativeTo: this.activatedRoute });
      //update badges
      if (!!result) {
        this.addArchive(result);
        const splitPath = (<string>result[0]).split('\/');
        this.startDownload(splitPath[splitPath.length - 1]);
      }
    });
  }

  addArchive(archive) {
    this.getPDFArchives();
  }

}
