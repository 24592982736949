import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BadgeResponse, BadgeRequest } from '../models/badge';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class BadgeService extends BaseService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private snackbarService: SnackbarService,) {
    super();
  }

  /** 
   * get all badges
   * @returns BadgeResponse[]
   */
  public getBadges(): Promise<void | BadgeResponse[]> {
    return this.http.get<BadgeResponse[]>(this.getBaseUrl() + 'badges').toPromise().catch(this.badgeErrorHandler);
  }

  /**
   * get single badge by id
   * @param id 
   */
  public getBadgeById(id: number): Promise<void | BadgeResponse> {
    return this.http.get<BadgeResponse>(this.getBaseUrl() + 'badges/' + id).toPromise().catch(this.badgeErrorHandler);
  }

  /**
   * create badge from request data
   * @param data 
   */
  public createBadge(data: BadgeRequest): Promise<void | BadgeResponse> {
    return this.http.post<BadgeResponse>(this.getBaseUrl() + 'badges', data).toPromise().catch(this.badgeErrorHandler);
  }

  /**
   * update existing badge from request data
   * @param id 
   * @param data 
   */
  public updateBadge(id: number, data: BadgeRequest): Promise<void | BadgeResponse> {
    return this.http.put<BadgeResponse>(this.getBaseUrl() + 'badges/' + id, data)
      .toPromise()
      .catch(this.badgeErrorHandler);
  }

  public deleteBadge(id: number) {
    return this.http.delete<BadgeResponse>(this.getBaseUrl() + 'badges/' + id)
      .toPromise()
      .catch(this.badgeErrorHandler);
  }

  badgeErrorHandler = (error) => {
    switch (error.status) {
      case 400:
        this.snackbarService.openSnackBar({ text: 'badge.message.error.duplicaterfid', color: 'warn' });
        break;
      case 422:
        this.snackbarService.openSnackBar({ text: '[T]badge.message.error.form error stuff', color: 'warn' });
        break;
      case 403:
        this.snackbarService.openSnackBar({ text: 'errors.authentication.permission', color: 'warn' });
        break;
      default:
        break;
    }
  }
}
