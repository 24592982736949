import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  ngOnInit(): void {
  }

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackbarService
  ) { }

  passwordResetForm = this.fb.group({
    password: ['', [Validators.minLength(2), Validators.maxLength(60)]],
    passwordrepeat: ['', [Validators.minLength(2), Validators.maxLength(60)]]
  });



  onSend() {

    const token = this.route.snapshot.params.token;

    if (!!token) {
      // TODO: check passwords

      const pws = this.passwordResetForm.getRawValue();

      if (pws.password === pws.passwordrepeat) {
        this.authService.passwordReset(token, pws.password).then(res => {

          this.snackbarService.openSnackBar({ text: "Passwort erfolgreich zurückgesetzt", color: 'success' });
          this.router.navigateByUrl('/auth/login');
        }).catch(e => {
          this.snackbarService.openSnackBar({ text: "Fehler beim zurücksetzen des Passwortes. Ist der Link eventuell abgelaufen oder das Passwort zu unsicher", color: 'warn' });
        });
      } else {
        // TODO: error
        
        this.snackbarService.openSnackBar({ text: "Passwörter stimmen nicht überein", color: 'warn' });

        console.log("duck")
      }
    }
  }

}
