<app-layout>
  <div class="container">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
      <!-- Benutzer-ID Spalte -->
      <ng-container matColumnDef="userId">
        <th mat-header-cell *matHeaderCellDef>User ID</th>
        <td mat-cell *matCellDef="let action">{{ action.user_id }}</td>
      </ng-container>
  
      <!-- Pfad Spalte -->
      <ng-container matColumnDef="path">
        <th mat-header-cell *matHeaderCellDef>Pfad</th>
        <td mat-cell *matCellDef="let action">{{ action.path }}</td>
      </ng-container>
  
      <!-- Methode Spalte -->
      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef>Methode</th>
        <td mat-cell *matCellDef="let action">{{ action.method }}</td>
      </ng-container>
  
      <!-- Daten Spalte -->
      <ng-container matColumnDef="data">
        <th mat-header-cell *matHeaderCellDef>Daten</th>
        <td mat-cell *matCellDef="let action">{{ action.data }}</td>
      </ng-container>
  
      <!-- Erstellungsdatum Spalte -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>Erstellungsdatum</th>
        <td mat-cell *matCellDef="let action">{{action.created_at }}</td>
      </ng-container>
  
      <!-- Definieren der Spaltenreihenfolge -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

  </div>
  
</app-layout>
