import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { promise } from 'selenium-webdriver';
import { LoginResponse } from '../models/login.response';
import { User } from '../models/user';
import { BaseService } from './base.service';
import { SnackbarService } from './snackbar.service';
import { StorageService } from './storage.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private snackbarService: SnackbarService,
    private router: Router) {
    super();

    this.getUser();
  }

  private getUrl(): string {
    return `${this.getBaseUrl()}auth`;
  }

  public currentUser;

  private getUser() {
    this.currentUser = this.storageService.getUser();
  }

  setUser(user: User) {
    this.currentUser = user;
  }

  /**
   * check if user is logged in
   */
  public isLoggedIn() {
    let token = this.storageService.getAuthorizationToken();

    return !!token;
  }

  /**
   * sends login request to api
   * @param credentials
   *
   */
  public login(credentials: { 'username', 'password' }) {
    let test = this.httpClient.post<LoginResponse>(`${this.getUrl()}/login`, credentials).toPromise().then(
      (res: LoginResponse) => {

        if (!!res.access_token) {
          //login success
          this.storageService.setAuthorizationToken(res.access_token);
          this.storageService.setUser(res.user);
          this.setUser(res.user);

          let redirectUrl = this.storageService.getRedirectUrl();

          if (!!redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
            this.storageService.removeRedirectUrl();
          } else {
            //navigate to dashboard
            this.router.navigateByUrl('dashboard');
          }
        }
      }
    ).catch((error) => {
      //error when logging in
      switch (error.status) {
        case 401:
          //wrong credentials or user not existing
          this.snackbarService.openSnackBar({ text: "errors.login.credentials", color: 'warn' });
          break;
        case 429:
          //too many requests blocked for x time
          this.snackbarService.openSnackBar({ text: "errors.login.attempts", color: 'warn' });
          break;
        default:
          //some other status
          this.snackbarService.openSnackBar({ text: "errors.login.unknown", color: 'warn' });
          console.log(error)
          break;
      }
    });
  }
  //erweitert by atm
  public passwordForgotten(credentials: { email, username }) {
    // Überprüfen, ob E-Mail und Benutzername vorhanden sind
    if (!credentials.email || !credentials.username) {
      this.snackbarService.openSnackBar({ text: "Bitte füllen Sie alle Felder aus.", color: 'warn' });
    } else {
      // Alle erforderlichen Informationen sind vorhanden, Anfrage an die API senden
      this.httpClient.post(this.getUrl() + '/password-forgotten', credentials).toPromise().then(
        (res) => {
          // Erfolg - Passwort-Zurücksetzungslink wurde gesendet
          this.snackbarService.openSnackBar({ text: "Passwort-Zurücksetzungslink wurde gesendet.", color: 'success' });
        },
        (error) => {
          // Fehlerbehandlung - API hat einen Fehler zurückgegeben
          this.snackbarService.openSnackBar({ text: "Überprüfung fehlgeschlagen. Bitte überprüfen Sie Ihre Eingaben.", color: 'warn' });
        }
      );
    }
  }
  

  public passwordReset(token, password): Promise<any> {
    return this.httpClient.post(this.getUrl() + '/password-reset', { token, password }).toPromise();
  }


  /**
   * logout user in client and server
   *
   */
  public logout() {
    this.storageService.removeAuthorizationToken();
    this.storageService.removeUser();

    this.storageService.removeCacheBadges();
    this.storageService.removeCacheUsers();
    this.storageService.removeCacheRoles();


    this.httpClient.get(this.getBaseUrl() + 'auth/logout');
    this.router.navigateByUrl('/auth/login');
  }

  public passwordSet(data: { users: number[], password: string }): Promise<any> {
    return this.httpClient.post(`${this.getUrl()}/password-setter`, data).toPromise();
  }
}
