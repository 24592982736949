import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { User, UserRequest } from '../models/user';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';
import { SnackbarService } from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private snackbarService: SnackbarService,
  ) {
    super();
  }

  getUsers(): Promise<User[]> {
    return this.http.get<User[]>(this.getBaseUrl() + 'users').toPromise().catch(this.handleHttpError);
  }

  getUser(id: Number): Promise<User> {
    return this.http.get<User>(this.getBaseUrl() + 'users/' + id).toPromise().catch(this.handleHttpError);
  }

  getUserByUsername(username: string) {

  }

  public getSupervisors(): Promise<User[]> {
    return this.http.get<User[]>(this.getBaseUrl() + 'users/supervisors').toPromise().catch(this.handleHttpError);
  }

  updateUser(id: number, data: UserRequest) {
    if (!data.can_login) data.can_login = false;
    return this.http.put<User>(this.getBaseUrl() + 'users/' + id, data).toPromise().catch(this.handleHttpError);
  }

  createUser(data: UserRequest) {
    if (!data.can_login) data.can_login = false;
    return this.http.post<User>(this.getBaseUrl() + 'users', data).toPromise().catch(this.handleHttpError);
  }

  deleteUser(id: number) {
    return this.http.delete(this.getBaseUrl() + 'users/' + id).toPromise().catch(this.handleHttpError);
  }

  private handleHttpError = (error) => {
    switch (error.status) {
      case 403:
        this.snackbarService.openSnackBar({ text: 'errors.authentication.permission', color: 'warn' });
        break;
      case 400:
        this.snackbarService.openSnackBar({ text: 'errors.badrequest', color: 'warn' });
        break;
      case 422:
        this.snackbarService.openSnackBar({ text: '[T]user.message.error.form error stuff', color: 'warn' });
        break;
      case 500:
        this.snackbarService.openSnackBar({ text: 'errors.server.500', color: 'warn' });
        break;

      default:
        break;
    }
    return null;
  }
}
