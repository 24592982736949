<app-toolbar></app-toolbar>
<div class="wrapper">
    <mat-card class="sidenav" *ngIf="componentName !== 'PdfExportListComponent' && componentName !== 'PersonViewComponent'">
        <mat-card-content>
            <div>
                <div>
                    <h2>{{'common.filter' | translate | titlecase}}</h2>
                    <form [formGroup]="filterFG">
                        <!-- <div style="padding:10px;" class="filter-wrapper"> -->
                        <mat-form-field appearance="fill">
                            <mat-label>{{'filter.text' | translate}}</mat-label>
                            <input matInput type="text" tabindex="30" formControlName="text" />
                            <!-- <mat-hint>Hint</mat-hint> -->
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>{{'common.roles' | translate}}</mat-label>
                            <mat-select tabindex="31" multiple formControlName="roles">
                                <mat-option [value]="role.id" *ngFor="let role of roles">{{ role.name + ' (' +
                                    role.users.length + ')' }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>{{'filter.supervisors' | translate}}</mat-label>
                            <mat-select tabindex="32" multiple formControlName="supervisors">
                                <mat-option [value]="supervisor.id" *ngFor="let supervisor of supervisors">
                                    {{ supervisor.username + ' (' + (!!supervisor.personell? supervisor.personell.length : '0') +
                                     ')'}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- </div> -->

                        <div class="button-wrapper">
                            <button mat-button color="primary" type="submit" (click)="applyFilter()">{{'filter.apply' | translate }}</button>
                            <button mat-button (click)="clearFilter()">{{'filter.clear' | translate }}</button>
                        </div>
                    </form>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <div class="content">
        <div class="content-wrapper">
            <ng-content></ng-content>
        </div>
    </div>
</div>