<div class="wrapper">
  <div class="background-image"></div>
  <div class="login-wrapper">
    <mat-card class="login-card mat-elevation-z1">
      <mat-card-content>
        <h1 class="mat-h1">{{'common.password-forgotten' | translate | titlecase}}</h1>
        <form class="login-form" [(formGroup)]="passwordForgotForm">
          <mat-form-field appearance="">
            <mat-label>{{'login.username' | translate}}</mat-label>
            <input matInput formControlName="username" tabindex="1" autofocus="true" />
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>

          <mat-form-field appearance="">
            <mat-label>{{'common.email' | translate}}</mat-label>
            <input matInput type="email" formControlName="email" tabindex="2" />
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="onSend()"
              disabled="{{!passwordForgotForm.dirty? 'true' : 'false'}}" tabindex="3">{{'common.send' | translate |
              uppercase}}</button>
          <br />
          <a routerLink="/auth/login" tabindex="4">{{'common.login' | translate}}</a>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>