<div class="wrapper">
  <div class="background-image"></div>
  <div class="login-wrapper">
    <mat-card class="login-card mat-elevation-z1">
      <mat-card-content>
        <h1 class="mat-h1">{{'common.login' | translate | titlecase}}</h1>
        <form class="login-form" [(formGroup)]="loginForm">
          <mat-form-field appearance="">
            <mat-label>{{'login.username' | translate}}</mat-label>
            <input matInput formControlName="username" tabindex="1" autofocus="true" />
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>

          <mat-form-field appearance="">
            <mat-label>{{'login.password' | translate}}</mat-label>
            <input matInput type="password" formControlName="password" tabindex="2" />
            <!-- <mat-hint>Hint</mat-hint> -->
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="onLogin()"
            disabled="{{!loginForm.dirty? 'true' : 'false'}}" tabindex="3">{{'common.login' | translate |
            uppercase}}</button>
          <br />
          <a routerLink="/auth/password-forgotten" tabindex="4">{{'login.password.forgotten' | translate}}</a>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>