import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Badge, BadgeRequest } from 'src/app/models/badge';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { BadgeService } from 'src/app/services/badge.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-badge-form',
  templateUrl: './badge-form.component.html',
  styleUrls: ['./badge-form.component.scss']
})
export class BadgeFormComponent implements OnInit {

  badgeForm: UntypedFormGroup;
  users: User[] = [];

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private badgeService: BadgeService,
    private storageService: StorageService,

    public dialogRef: MatDialogRef<BadgeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Badge) { }

  ngOnInit(): void {
    this.badgeForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      rfid: ['', [Validators.required, Validators.pattern(/^[0-9A-F]{8}$/)]],
      user: [0]
    });

    console.log(this.data)
    if (!!this.data) {
      this.setData(this.data);
    }
    this.users = this.storageService.getCacheUsers();
    this.getUsers();
  }

  private setData(badge) {
    this.badgeForm.patchValue({
      name: badge.name,
      rfid: badge.rfid,
      user: badge.user_id
    });
  }

  private getUsers() {
    this.userService.getUsers().then(users => this.users = users.sort((a, b) => {
      return a.username > b.username ? 1 : -1;
    })).catch();
  }

  onSubmit() {
    let formData = this.badgeForm.getRawValue();
    let request: BadgeRequest = { rfid: formData['rfid'], name: formData['name'], user_id: formData.user };

    if (!!this.data) {
      console.log('updating badge')
      this.badgeService.updateBadge(this.data.id, request).then(badge => {
        if (!!badge) {
          this.snackbarService.openSnackBar({ text: 'badge.message.saved', color: 'success' });
          this.dialogRef.close(badge);
          return;
        } else {
          this.snackbarService.openSnackBar({ text: 'badge.message.error.duplicaterfid', color: 'warn' });
        }
      });
    } else {
      this.badgeService.createBadge(request).then(badge => {
        if (!!badge) {
          this.snackbarService.openSnackBar({ text: 'badge.message.saved', color: 'success' });
          this.dialogRef.close(badge);
          return;
        } else {
          this.snackbarService.openSnackBar({ text: 'badge.message.error.duplicaterfid', color: 'warn' });
        }
      });
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }

}
