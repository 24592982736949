import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PersonFormComponent } from 'src/app/components/modals/person-form/person-form.component';
import { User } from 'src/app/models/user';
import { BadgeService } from 'src/app/services/badge.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { UserService } from 'src/app/services/user.service';
import { DeleteConfirmationComponent } from 'src/app/components/modals/delete-confirmation/delete-confirmation.component';
import { StorageService } from 'src/app/services/storage.service';
import { Permissions } from 'src/app/permissions';
import { Title } from '@angular/platform-browser';
import { Filter } from 'src/app/models/filter';
import { FilterService } from 'src/app/services/filter.service';
import { AuthService } from 'src/app/services/auth.service';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-people-list',
  templateUrl: './people-list.component.html',
  styleUrls: ['./people-list.component.scss']
})
export class PeopleListComponent implements OnInit {

  public READ_PEOPLE = Permissions.READ_PEOPLE;
  public WRITE_PEOPLE = Permissions.WRITE_PEOPLE;
  public DELETE_PEOPLE = Permissions.DELETE_PEOPLE;


  //
  users: User[] = [];
  rawUsers: User[] = [];
  displayedColumns = [
    'lastname',
    'firstname',
    'username',
    'actions'
  ];


  //pagination
  pageLength = 0;
  pageIndex = 0;
  pageSize = 10;
  pageSizeOptions = [10, 20, 50, 100, 200];

  pageEvent: PageEvent;

  //table
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userDataSource: MatTableDataSource<User> = new MatTableDataSource<User>();
  routeQueryParams$: Subscription;

  //filter
  activeFilter: Filter = { text: '', roles: [], supervisors: [] };
  private subscribedFilter: Subscription = null;
  
  private currentUser: User;

  constructor(
    private authService: AuthService,
    private badgeService: BadgeService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private roleService: RoleService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private storageService: StorageService,
    private titleService: Title,
    private filterService: FilterService
  ) { }

  ngOnInit(): void {

    this.currentUser = this.authService.currentUser;

    this.subscribedFilter = this.filterService.getFilterObservable().subscribe(filter => {
      this.activeFilter = filter;
      this.processUsers(this.rawUsers);
    });

    this.titleService.setTitle('Chronos | People');

    //should the edit form open?
    this.routeQueryParams$ = this.activatedRoute.queryParams.subscribe((params) => {
      if (params['openDialog']) {
        if (params['userId']) { //edit badge
          this.userService.getUser(params['userId']).then(user => {
            this.openDialog(user);
          });
        } else { //create badge
          this.openDialog();
        }
      }

      if (params.peoplePageIndex) {
        this.pageIndex = params.peoplePageIndex;
      }
      if (params.peoplePageSize) {
        this.pageSize = params.peoplePageSize;
      }
    });

    this.processUsers(this.storageService.getCacheUsers());

    this.getUsers();
  }

  ngAfterViewInit() {
    this.userDataSource.paginator = this.paginator;
    this.getUsers();
  }

  ngOnDestroy() {
    this.subscribedFilter.unsubscribe();
  }

  onRefresh() {

    if (!this.users) this.users = [];
    else this.processUsers(this.users);
    this.getUsers(true);
  }

  private processUsers = (users) => {
    this.rawUsers = !!users ? users : [];

    this.users.filter(u => this.roleService.checkPermissionWithRolePower(this.currentUser, u, [this.READ_PEOPLE], 'AND'));
    this.users = this.rawUsers.filter((u) => !u.is_hidden).sort((a, b) => a.lastname > b.lastname? 1 : -1);
    this.users = this.filterService.filterUsers(this.users, this.activeFilter);
    this.userDataSource.data = this.users;
    // this.userDataSource.data = this.users.filter((user) => !user.is_hidden);
  }

  getUsers(refresh = false) {

    this.userService.getUsers()
    .then(users => {
      const filteredUsers = users.filter(user => {
        const userRolePower = this.roleService.getRolePower(user);
        const currentUserRolePower = this.roleService.getRolePower(this.currentUser);
        const isCurrentUser = user.id === this.currentUser.id;
        // Überprüfen, ob der Benutzer weniger Rolepower hat als der aktuelle Benutzer
        return (userRolePower < currentUserRolePower) || isCurrentUser;
      });
      // Die filteredUsers-Liste enthält nun die Benutzer, die die Bedingung erfüllen
      this.storageService.setCacheUsers(filteredUsers);
      this.processUsers(filteredUsers);

      if (refresh) {
        this.snackbarService.openSnackBar({ text: 'messages.reloaded.data', color: 'success' });
      }
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Benutzerdaten:', error);
      // Führen Sie hier die gewünschte Fehlerbehandlung durch
    });

  }


  public openDialog(badge = null) {
    const dialogRef = this.dialog.open(PersonFormComponent, {
      width: '400px',
      data: badge
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { peoplePageIndex: this.pageIndex, peoplePageSize: this.pageSize } });
      //update badges
      if (!!result) {
        this.updateUsers(result);
      }
    });
  }

  updateUsers(user) {
    let found = this.rawUsers.find((b, index) => {
      if (b.id == user.id) {

        //overwrite user
        this.rawUsers[index] = user;
        return b;
      }
    });

    //push new user
    if (!found) {
      this.rawUsers.push(user);
    }

    this.processUsers(this.rawUsers);
  }

  deleteUser(id: number) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      width: '350px',
      data: { text: 'messages.delete.confirmation', textData: '' },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteUser(id).then(
          () => {
            this.rawUsers = this.rawUsers.filter((user) => {
              if (user.id != id) return user;
            });

            this.processUsers(this.rawUsers);
            this.snackbarService.openSnackBar({ text: 'user.message.deleted', color: 'success' })
          }).catch((error) => {
            switch (error.status) {
              case 403:
                //no permission
                this.snackbarService.openSnackBar({ text: 'error.authentication.permission', color: 'error' });
                break;

              default:
                break;
            }
          });
      }
    })


  }

  removeSearchFilter() {

  }

  //pagination
  setParameterFromTable(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.pageLength = pageEvent.length;
    this.pageSize = pageEvent.pageSize;

    this.router.navigate(['.'], { relativeTo: this.activatedRoute, queryParams: { peoplePageIndex: this.pageIndex, peoplePageSize: this.pageSize } });
  }

  setTableFromParameter() {
    this.paginator.pageIndex = this.pageIndex;
    this.paginator.pageSize = this.pageSize;
  }
}
