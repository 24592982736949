import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from '../models/user';
import { AuthService } from '../services/auth.service';
import { RoleService } from '../services/role.service';
import { StorageService } from '../services/storage.service';
import { UserService } from '../services/user.service';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {
  private currentUser: User = null;
  private userPermissions = [];
  private permissions = [];
  private logicalOp = 'AND';
  private isHidden = true;
  private user: User;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private roleService: RoleService,
    private storageService: StorageService) {}

  ngOnInit() {
    this.currentUser = this.authService.currentUser;
    this.userPermissions = this.currentUser.roles.map((role => role.permissions));
    this.userPermissions = [].concat.apply([], this.userPermissions);
    this.updateView();
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
  }

  @Input()
  set hasPermissionOp(permop) {
    this.logicalOp = permop;
  }
  
  @Input()
  set hasPermissionUser(user) {
    this.user = user;
  }

  private updateView() {
    if (this.checkPermission()) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    if (!this.user) {
      let hasPermission = false;
      for (let checkPermission of this.permissions) {
        let permissionFound = this.userPermissions.find(p => p.name.toUpperCase() === checkPermission.toUpperCase());
        if (permissionFound) {
          hasPermission = true;
          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
      return hasPermission;
    } else {
      return this.roleService.checkPermissionWithRolePower(this.currentUser, this.user, this.permissions, this.logicalOp);
    }
  }
}
