<mat-toolbar class="toolbar" color="primary">
  <mat-label>{{ 'toolbar.title' | translate | titlecase }}</mat-label>
  <div>
    <button mat-button color="" routerLink="/dashboard">{{'menu.dashboard' | translate}}</button>
    <button mat-button color="" routerLink="/people">{{'menu.people' | translate}}</button>
    <button mat-button color="" routerLink="/badges" *hasPermission="[READ_BADGES]">{{'menu.badges' |
      translate}}</button>
    <button mat-button routerLink="/exports/pdf/" *hasPermission="[GENERATE_PDF]">{{'menu.exports' |
      translate}}</button>

    <button mat-button [matMenuTriggerFor]="menuAdministration"
      *hasPermission="[WRITE_ROLES, 'test']; op 'OR'">{{'menu.administration' | translate:user}}</button>
  </div>
  <button mat-flat-button [matMenuTriggerFor]="menu">{{'toolbar.greeting' | translate:user}}</button>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item disabled>[T] user settings</button>
  <button mat-menu-item (click)="onLogout()" class="warn-color">{{'common.logout' | translate | titlecase }}</button>
</mat-menu>

<mat-menu #menuAdministration="matMenu">
  <button mat-menu-item routerLink="/roles/list" *hasPermission="[WRITE_ROLES]">{{'menu.roles' | translate}}</button>
  <button mat-menu-item routerLink="/automation" disabled>{{'menu.automations' | translate}}</button>
  <button mat-menu-item routerLink="/timeentries" disabled>{{'menu.timeentries' | translate}}</button>
  <button mat-menu-item routerLink="/password-setter" *hasPermission="[SET_USER_PASSWORDS]">{{'menu.password-setter' | translate}}</button>
  <div style="display: flex;">
    <button mat-menu-item [routerLink]="['/user-actions', userId]" *hasPermission="[READ_USER_ACTIONS]">
      {{'menu.useractions' | translate}}
    </button>
    <input style="width: 20px; z-index: 1000;" type="text" placeholder="ID" [(ngModel)]="userId" (click)="$event.stopPropagation()">
  </div>
</mat-menu>
