import { Component, OnInit, ViewChild } from '@angular/core';
import { User, UserRequest } from '../../models/user';
import { TimeEntry } from '../../models/timeentry';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user.service';
import { SnackbarService } from '../../services/snackbar.service';
import { Title } from '@angular/platform-browser';
import { MatAccordion } from '@angular/material/expansion';
import { RoleService } from '../../services/role.service';
import { Role } from '../../models/role';
import { Subscription } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { Filter } from 'src/app/models/filter';
import { AuthService } from '../../services/auth.service';
import { Permissions } from '../../permissions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
    
  public READ_PEOPLE = Permissions.READ_PEOPLE;
  activeFilter: Filter = { text: '', roles: [], supervisors: [] };
  private subscribedFilter: Subscription = null;

  public users: User[] = [];
  public rawUsers: User[] = [];
  public times = [];
  public roles: Role[] = [];
  
  private currentUser: User

  @ViewChild(MatAccordion) accordion: MatAccordion;

  displayedColumns = ['username', 'start_time', 'end_time', 'date'];

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private titleService: Title,
    private roleService: RoleService,
    private filterService: FilterService,
    
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.currentUser;
    this.subscribedFilter = this.filterService
      .getFilterObservable()
      .subscribe((filter) => {
        this.activeFilter = filter;
        this.processUsers(this.rawUsers);
      });
    this.titleService.setTitle('Chronos | Dashboard');
    this.getUsers();
    this.getRoles();
  }

  ngOnDestroy() {
    this.subscribedFilter.unsubscribe();
  }

  getUsers(refresh = false) {
   
    this.userService.getUsers()
    .then(users => {
      const filteredUsers = users.filter(user => {
        const userRolePower = this.roleService.getRolePower(user);
        const currentUserRolePower = this.roleService.getRolePower(this.currentUser);
        const isCurrentUser = user.id === this.currentUser.id;
        // Überprüfen, ob der Benutzer weniger Rolepower hat als der aktuelle Benutzer
        return (userRolePower < currentUserRolePower) || isCurrentUser;
      });
      // Die filteredUsers-Liste enthält nun die Benutzer, die die Bedingung erfüllen
      this.storageService.setCacheUsers(filteredUsers);
      this.processUsers(filteredUsers);

      if (refresh) {
        this.snackbarService.openSnackBar({ text: 'messages.reloaded.data', color: 'success' });
      }
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Benutzerdaten:', error);
     
    });

  }

  getRoles(refresh = false) {
    this.roles = this.storageService.getCacheRoles();
    if (!this.roles) this.roles = [];

    this.roleService.getRoles().then((roles) => {
      this.storageService.setCacheRoles(roles);
      this.roles = roles;
    });
  }

  private processUsers = (users) => {
   // Sicherstellen, dass `users` nicht null oder undefined ist
this.rawUsers = users || [];

// Transformation der Benutzerdaten
this.users = this.rawUsers.map((user) => {
  // Überprüfen, ob der Benutzer einen offenen Zeiteintrag hat
  user.openentry = user.timeentries?.find((te) => {
    if (te.end_time == null && this.isToday(new Date(te.start_time))) {
      user.openentry = te;
      user.isBadgedIn = true;
      return te;
    }
  });

  // Konvertieren und Sortieren von Zeiteinträgen
  user.timeentries = user.timeentries
    .map((e) => {
      e.start_time = new Date(e.start_time);
      if (!!e.end_time) e.end_time = new Date(e.end_time);
      return e;
    })
    .sort((a, b) =>
      a.start_time != b.start_time
        ? a.start_time > b.start_time
          ? 1
          : -1
        : a.end_time > b.end_time
        ? 1
        : -1
    );

  return user;
});

// Filtern der Benutzerdaten
this.users = this.rawUsers
  .filter((user) => {
    // Überprüfen, ob der Benutzer der aktuelle Benutzer ist
    const isCurrentUser = user.id === this.currentUser.id;

    // Rollenmacht des Benutzers und des aktuellen Benutzers abrufen
    const userRolePower = this.roleService.getRolePower(user);
    const currentUserRolePower = this.roleService.getRolePower(
      this.currentUser
    );

    // Überprüfen, ob der Benutzer sichtbar sein sollte
    if (currentUserRolePower === 999) {
      return true; // Ein eingeloggter Admin darf alles sehen
    } else if (userRolePower < currentUserRolePower || isCurrentUser) {
      return true; // Ein Benutzer darf Personen mit geringerer Rollenmacht sehen oder sich selbst
    }

    return false; // Alle anderen Benutzer werden herausgefiltert
  })
  .sort((a, b) => a.username.localeCompare(b.username)) // Benutzer nach Benutzernamen sortieren
  .sort((a, b) =>
    a.isBadgedIn == b.isBadgedIn ? 0 : a.isBadgedIn ? -1 : 1
  ); // Benutzer nach "isBadgedIn" Eigenschaft sortieren

// Anwenden des aktiven Filters und Filtern ausgeblendeter Benutzer
this.users = this.filterService
  .filterUsers(this.users, this.activeFilter)
  .filter((u) => !u.is_hidden);

  };

  private isToday(someDate: Date): Boolean {
    const today = new Date();
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    );
  }

  onRefresh() {
    this.getUsers(true);
  }
}
