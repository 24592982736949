<app-layout>
  <div class="container">
    <h1>{{ "roles.membersOfRole" | translate }} {{ role?.name }}</h1>
    <table
      mat-table
      [dataSource]="users"
      class="mytable dark-header"
      style="width: 100%; margin-top: 10px"
    >
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!-- Username Column -->
      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>
          {{ "common.username" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.username }}</td>
      </ng-container>

      <!-- Role Name Column -->
      <ng-container matColumnDef="roleName">
        <th mat-header-cell *matHeaderCellDef>
          {{ "roles.role" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ role?.name }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ "roles.removeFromRole" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            color="primary"
            class="c-pointer"
            *hasPermission="[WRITE_PEOPLE]"
            (click)="removeFromRole(element)"
          >
            remove</mat-icon
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsRoles"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsRoles"></tr>
    </table>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 20, 50, 200]">
    </mat-paginator>
  </div>
</app-layout>
