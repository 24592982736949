import { Component, OnInit, ViewChild } from '@angular/core';
import { RoleService } from '../../../services/role.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Role } from '../../../models/role';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { User } from '../../../models/user';
import { MatDialog } from '@angular/material/dialog';
import { PersonFormComponent } from '../../modals/person-form/person-form.component';
import { Permissions } from '../../../permissions';
import { BadgeService } from '../../../services/badge.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { UserService } from '../../../services/user.service';
import { StorageService } from '../../../services/storage.service';
import { Title } from '@angular/platform-browser';
import { FilterService } from '../../../services/filter.service';
import { DeleteConfirmationComponent } from '../../modals/delete-confirmation/delete-confirmation.component';
import { Subscription } from 'rxjs';
import { Filter } from '../../../models/filter';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-members-of-role',
  templateUrl: './members-of-role.component.html',
  styleUrls: ['./members-of-role.component.scss'],
})
export class MembersOfRoleComponent implements OnInit {
  role: Role;
  users: MatTableDataSource<User>;
  rawUsers: User[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumnsRoles = ['username', 'roleName', 'actions'];
  public WRITE_PEOPLE = Permissions.WRITE_PEOPLE;

  // filter
  activeFilter: Filter = { text: '', roles: [], supervisors: [] };
  private subscribedFilter: Subscription = null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private roleService: RoleService,
    private dialog: MatDialog,
    private badgeService: BadgeService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private storageService: StorageService,
    private filterService: FilterService
  ) {}

  ngOnInit(): void {
    const id = parseInt(this.activatedRoute.snapshot.paramMap.get('id'));
    this.roleService.getRole(id).then((response) => {
      this.role = response;
      this.rawUsers = response.users;
      this.users = new MatTableDataSource(this.rawUsers);
      this.users.paginator = this.paginator;

      this.subscribedFilter = this.filterService
        .getFilterObservable()
        .subscribe((filter) => {
          this.activeFilter = filter;
          this.processUsers(this.users.data);
        });
    });
  }

  ngOnDestroy() {
    this.subscribedFilter.unsubscribe();
  }

  removeFromRole(user: User) {
    this.userService.getUser(user.id).then((response) => {
      const newRoleIds = response.roles
        .filter((e) => {
          if (e.id !== this.role.id) return e;
        })
        .map((e) => e.id);
      this.userService.updateUser(user.id, { roles: newRoleIds, badges: user.badges.map(b => b.id), can_login: user.can_login, firstname: user.firstname, lastname: user.lastname, is_hidden: user.is_hidden, is_supervisor: user.is_supervisor, supervisor_id: user.supervisor_id, username: user.username, email: user.email }).then(() => {
        this.rawUsers = this.rawUsers.filter((e) => {
          if (e.id !== user.id) return e;
        });
        this.users.data = this.rawUsers;
        this.processUsers(this.users.data);
        this.snackbarService.openSnackBar({
          text: 'roles.message.userRemovedFromRole',
          color: 'success',
        });
      });
    });
  }

  private processUsers = (users) => {
    // this.rawUsers = !!users ? users : [];

    this.users.data = this.filterService.filterUsers(
      this.rawUsers,
      this.activeFilter
    );
    this.users.data = this.users.data
      .filter((u) => !u.is_hidden)
      .sort((a, b) => (a.username > b.username ? 1 : -1));
  };
}
