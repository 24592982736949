import {Component, OnDestroy, OnInit} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';
import { User } from 'src/app/models/user';
import { Permissions } from 'src/app/permissions';
import { FilterService } from 'src/app/services/filter.service';
import { RoleService } from 'src/app/services/role.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy {
  componentName: string;
  READ_BADGES = Permissions.READ_BADGES;

  public roles: Role[] = [];
  public supervisors: User[] = [];

  filterFG: UntypedFormGroup;

  filter = [];

  private filterSubscription: Subscription = null;


  constructor(
    private userService: UserService,
    private storageService: StorageService,
    private roleService: RoleService,
    private filterService: FilterService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.componentName = this.route.snapshot.component['name'];

    //get cached users
    this.processUsers(this.storageService.getCacheSupervisors());
    this.roles = this.storageService.getCacheRoles();

    //get data from server
    this.getSupervisors();
    this.getRoles();

    this.filterFG = this.fb.group({
      text: [],
      roles: [],
      supervisors: [],
    });

    this.filterSubscription = this.filterService.getFilterObservable().subscribe((filter) => {
      this.filter = filter;
      this.roles = this.storageService.getCacheRoles();
      this.supervisors = this.storageService.getCacheSupervisors();
      this.processUsers(this.supervisors);

      this.updateFilterFG(filter);
    });
  }

  updateFilterFG(filter) {
    this.filterFG.patchValue(filter);
  }

  getSupervisors() {
    this.userService.getSupervisors().then((supervisors) => {
      this.processUsers(supervisors);
      this.storageService.setCacheSupervisors(supervisors);
    });
  }

  //load roles from server
  getRoles() {
    this.roleService.getRoles().then(
      roles => {
        this.roles = roles;
        this.storageService.setCacheRoles(roles);
      }
    );
  }

  // filter and sort users
  processUsers = function(users: User[] = []): void {
      this.supervisors = users.sort((a, b) => a.username > b.username ? 1 : -1);
  };

  public applyFilter() {
    this.filterService.setFilter(this.filterFG.getRawValue());
  }

  public clearFilter() {
    this.filterService.clearFilter();
    this.updateFilterFG({roles: [], supervisors: [], text: ''});
  }

  ngOnDestroy() {
    if (this.filterSubscription)
      this.filterSubscription.unsubscribe();
  }

}
