<form [(formGroup)]="exportForm" class="export-form">
    <h2>{{('exports.pdf.roles.title') | translate}}</h2>

    <mat-form-field appearance="fill">
        <mat-label>{{'common.roles' | translate}}</mat-label>
        <mat-select tabindex="3" formControlName="role_id" required>
            <mat-option class="mat-select-panel" [value]="role.id" *ngFor="let role of roles">{{ role.name + ' (' + role.users.length + ')' }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="datePicker" formControlName="date" (click)="openDatePicker(datePicker)" />
        <mat-datepicker-toggle style="color: black;" matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker  startView="multi-year" (monthSelected)="closeDatePicker($event, datePicker)"></mat-datepicker>
    </mat-form-field>
    <div class="button-wrapper">
        <button mat-flat-button color="primary" type="submit" tabindex="4" (click)="onSubmit()" [disabled]="exportForm.invalid">{{'common.generate'| translate }}</button>
        <button mat-button type="button" color="warn" tabindex="5" (click)="onCancel()">{{'common.close'| translate }}</button>
    </div>
</form>