import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private storageService: StorageService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    //always requires json
    const updatedReq = req.clone({
      headers: req.headers.set('Accept', "application/json")
    });

    if (!!this.storageService.getAuthorizationToken()) {
      // Get the auth token from the service.
      const authToken = this.storageService.getAuthorizationToken();

      // console.log('adding auth token');
      // Clone the request and replace the original headers with
      // cloned headers, updated with the authorization.
      // will be redundant when JWTs are stored as a cookie
      const authReq = updatedReq.clone({
        headers: req.headers.set('Authorization', "bearer " + authToken)
      });

      // send cloned request with header to the next handler.
      return next.handle(authReq);
    }
    return next.handle(updatedReq);
  }
}