import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { SnackbarService } from './snackbar.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private router: Router,
    private storageService: StorageService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        //reroute on 401 error
        if (error.status == 401) {

          //save route to redirect after login
          if (this.router.url !== '/auth/login')
            this.storageService.setRedirectUrl(this.router.url);

          //get token
          let token = this.storageService.getAuthorizationToken();
          try {

            //is token expired?
            if (!!token && JSON.parse(atob(token.split('.')[1])).exp < (+ new Date() / 1000)) {
              this.snackbarService.openSnackBar({ text: 'errors.authentication.expired', color: 'warn' });
            } else {
              //token invalid
              this.snackbarService.openSnackBar({ text: 'errors.authentication.required', color: 'warn' });
            }
          } catch (error) {
            //crash
            console.error('invalid token');
          }

          this.authService.logout();
        }

        return throwError({ status: error.status, error: error.message });
      }
      ));
  }

}