import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Role } from '../models/role';
import { AuthService } from './auth.service';
import { BaseService } from './base.service';
import { SnackbarService } from './snackbar.service';
import { User } from '../models/user';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends BaseService {
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private snackbarService: SnackbarService) {
    super();
  }

  public getRoles() {
    return this.http.get(this.getBaseUrl() + 'roles').toPromise().catch(this.handleHttpErrors);
  }

  public getRole(id) {
    return this.http.get(this.getBaseUrl() + 'roles/' + id).toPromise().catch(this.handleHttpErrors);
  }

  public putRole(id, updatedRole) {
    return this.http.put(this.getBaseUrl() + 'roles/' + id, updatedRole).toPromise();
  }

  getPermissions(): Observable<any> {
    return this.http.get<any>(this.getBaseUrl() + 'permissions').pipe(
      catchError(this.handleHttpErrors)
    );
  }

  public deleteRole(id: number): void {
    this.http.delete(this.getBaseUrl() + 'roles/' + id).toPromise().catch(this.handleHttpErrors);
  }

  public createRole(role: Role) {
    return this.http.post(this.getBaseUrl() + 'roles', role).toPromise();
  }

  handleHttpErrors = (error) => {
    switch (error.status) {
      case 400:
        //bad request
        console.log('test');
        break;
      case 422:
        this.snackbarService.openSnackBar({ text: '[T]role?.message.error.form error stuff', color: 'warn' });
        break;
      case 403:
      // no permission
      default:
        break;
    }

    return error;
  }
  
  public checkPermissionWithRolePower(loggedInUser: User, consideredUser: User, permissionsToCheck: any[], logicalOp: string) {
    let rolePower = this.getRolePower(consideredUser);
    let tmpPerms = this.copyArray(permissionsToCheck);
    for (let role of loggedInUser.roles) {
      if (tmpPerms.length == 0) {
        break;
      }
      if (rolePower <= parseInt(role.power)) {
        for (let checkPermission of permissionsToCheck) {
          if (!tmpPerms.includes(checkPermission, 0)) {
            continue;
          }
          let permissionFound = role.permissions.find(p => p.name.toUpperCase() === checkPermission.toUpperCase());
          if (permissionFound) {
            this.removeElement(tmpPerms, checkPermission);
            if (logicalOp === 'OR') {
              return true;
            }
          }
        }
      }
    }
    if (tmpPerms.length == 0) {
      return true;
    } else {
      return false;
    }
  }
  
  public getRolePower(consideredUser: User) {
    let rolePower = 0;
    if (!!consideredUser) {
      consideredUser.roles.forEach((role) => {
        let currentRolePower = parseInt(role.power);
        if (rolePower < currentRolePower) {
            rolePower = currentRolePower;
        }
      });
    }
    return rolePower;
  }
    
  private removeElement(array, element) {
    let index = array.indexOf(element, 0);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
  
  private copyArray(permissions) {
    let clonedArray = [];
    for (let perm of permissions) {
      clonedArray.push(perm);
    }
    return clonedArray;
  }
}
