import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: UntypedFormGroup;


  constructor(
    private authService: AuthService, 
    private fb: UntypedFormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      username: [''],
      password: [''],
    });

    if(this.authService.isLoggedIn()){
      this.router.navigateByUrl('/dashboard');
    }

  }

  onLogin() {
    this.authService.login(this.loginForm.getRawValue());
  }

}
