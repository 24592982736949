<app-layout>
    <div class="container">
        <h1>{{'menu.password-setter' | translate}}</h1>

        <form [(formGroup)]="passwordSetForm" (ngSubmit)="onSubmit()"
            style="display:flex; justify-content: flex-start; flex-direction: column;">

            <mat-form-field appearance="fill">
                <mat-label>{{'menu.people' | translate}}</mat-label>
                <mat-select tabindex="1" formControlName="users" multiple>
                    <mat-option *ngIf="users.length == 0">-</mat-option>
                    <mat-option [value]="user.id" *ngFor="let user of users">{{ user.username }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{'common.password' | translate}}</mat-label>
                <input matInput type="password" formControlName="password" tabindex="2" min="8" max="50" />
                
                <mat-hint *ngIf="passwordSetForm.get('password').errors?.minlength" class="hint-error">
                    {{'form.errors.minlength' | translate:{length:8} }}
                </mat-hint>
                <mat-hint *ngIf="passwordSetForm.get('password').errors?.maxlength" class="hint-error">
                    {{'form.errors.maxlength' | translate:{length:50} }}
                </mat-hint>

            </mat-form-field>


            <div class="button-flex">
                <button mat-flat-button color="primary" type="submit"
                    [disabled]="!passwordSetForm.touched || passwordSetForm.invalid">{{'common.submit'
                    |translate}}</button>
            </div>
        </form>

    </div>
</app-layout>