<app-layout>
  <div class="container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <h1>{{ this.router.url === '/roles/newRole' ? ("roles.addRole" | translate) : ("roles.editRole" | translate)}}</h1>
      <mat-form-field>
        <mat-label>{{ "roles.name" | translate }}</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ "roles.description" | translate }}</mat-label>
        <textarea matInput formControlName="description"></textarea>
      </mat-form-field>

      <div id="permissions">
        <p>{{ "roles.permissions" | translate }}</p>
        <ul>
          <li
            formArrayName="permissions"
            *ngFor="
              let permission of permissionsFormArray.controls;
              let i = index
            "
          >
            <mat-checkbox [formControlName]="i" color="primary">
              {{ allExistingPermissions[i].name }}
            </mat-checkbox>
          </li>
        </ul>
      </div>

      <mat-form-field>
        <mat-label>{{ "roles.rolePower" | translate }}</mat-label>
        <input matInput type="number" formControlName="power">
      </mat-form-field>

      <div id="bottomButtons">
        <button mat-stroked-button color="primary" routerLink="/roles/list">
          {{ "common.cancel" | translate }}
        </button>
        <button mat-stroked-button color="primary" type="submit">
          {{ "common.save" | translate }}
        </button>
      </div>
    </form>
  </div>
</app-layout>
