import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarComponent } from 'src/app/components/layout/snackbar/snackbar.component';
import { PDFExportFormComponent } from 'src/app/components/modals/pdfexport-form/pdfexport-form.component';
import { PersonFormComponent } from 'src/app/components/modals/person-form/person-form.component';
import { Badge } from 'src/app/models/badge';
import { TimeEntry } from 'src/app/models/timeentry';
import { User } from 'src/app/models/user';
import { Permissions } from 'src/app/permissions';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { TimeEntryFormComponent } from '../../modals/time-entry-form/time-entry-form.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeEntryService } from 'src/app/services/time-entry.service';
@Component({
  selector: 'app-person-view',
  templateUrl: './person-view.component.html',
  styleUrls: ['./person-view.component.scss']
})
export class PersonViewComponent implements OnInit {

  public WRITE_PEOPLE = Permissions.WRITE_PEOPLE;
  public READ_TIMES = Permissions.READ_TIMES;
  public READ_BADGES = Permissions.READ_BADGES;

  public userid: number = 0;
  public user: User = <User>{};

  displayedColumnsEntries = [
    'date',
    'start_time',
    'end_time',
    'time',
    'actions'
  ];
  displayedColumnsBadges = [
    'id',
    'name',
    'rfid',
  ];
  entryDatasource: MatTableDataSource<TimeEntry> = new MatTableDataSource<TimeEntry>();
  badgeDatasource: MatTableDataSource<Badge> = new MatTableDataSource<Badge>();
  @ViewChild('badgePaginator') badgePaginator: MatPaginator;
  @ViewChild('entryPaginator') entryPaginator: MatPaginator;

  private routeQueryParams$;
  endTimeForm: FormGroup;
  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private snackbarService: SnackbarService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private titleService: Title,
    private fb: FormBuilder,
    private timeEntryService: TimeEntryService
  ) { 
  }

  ngOnInit(): void {

    this.titleService.setTitle('Chronos | View Person');

    //should the edit form open?
    this.userid = +this.route.snapshot.paramMap.get('id');

    this.routeQueryParams$ = this.activatedRoute.queryParams.subscribe((params) => {
      if (params['openDialog']) { //edit badge
        this.userService.getUser(this.userid).then(user => {
          this.openDialog(user);
        })
      }
    });



    this.getCachedUser();

    this.getUser();
  }

  ngAfterViewInit() {
    this.badgeDatasource.paginator = this.badgePaginator;
    this.entryDatasource.paginator = this.entryPaginator;
  }

  getUser() {
    this.userService.getUser(this.userid).then(user => {
      this.user = user;

      //update title
      this.titleService.setTitle('Chronos | View ' + user.firstname + ' ' + user.lastname);

      this.entryDatasource.data = !user.timeentries ? [] : user.timeentries.sort((a, b) => {
        return a.start_time > b.start_time ? -1 : 1;
      });
      this.badgeDatasource.data = user.badges.sort((a, b) => {
        return a.name < b.name ? 1 : -1;
      });
    }).catch((error) => {
      switch (error.status) {
        case 404:
          this.snackbarService.openSnackBar({ text: 'user.message.error.notfound', color: 'warn' });
          break;
        default:
          break;
      }
    });
  }

  getCachedUser() {
    let foundUser = this.storageService.getCacheUsers().find((u) => {
      if (u.id === this.userid) {
        return u;
      }
    });

    this.user = foundUser;
  }

  public openDialog(badge = null) {
    const dialogRef = this.dialog.open(PersonFormComponent, {
      width: '400px',
      data: badge
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.router.navigate(['.'], { relativeTo: this.activatedRoute });
      //update badges
      if (!!result) {
        this.updateUser(result);
      }
    });
  }

  updateUser(user) {
    this.user = user;
  }

  openNewTimeEntryDialog(user: User, timeEntry: TimeEntry = null){
    const dialogRef = this.dialog.open(TimeEntryFormComponent, {
      width: '400px',
      height: '400px',
      data: {user: user, timeEntry: timeEntry }
    })

    dialogRef.afterClosed().subscribe(result=>{
      if (!!result) {
        this.getUser();
      }
    })
      
  }
  editingEndTime: TimeEntry | null = null; // Hier wird die bearbeitete Zeile gespeichert
 

  formatTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
  
    const hoursString = hours > 0 ? `${hours} Std` : '';
    const minutesString = remainingMinutes > 0 ? `${remainingMinutes} Min` : '';
  
    if (hours > 0 && remainingMinutes > 0) {
      return `${hoursString} ${minutesString}`;
    } else {
      return hoursString + minutesString;
    }
  }
}
