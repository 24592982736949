<form [(formGroup)]="personForm" class="person-form">
    <h3>{{(!!data? 'user.modal.title.edit' : 'user.modal.title.new') | translate}}</h3>

    <mat-form-field appearance="fill">
        <mat-label>{{'user.firstname' | translate}}</mat-label>
        <input matInput type="text" formControlName="firstname" tabindex="1" autofocus="" required />
        
        <mat-hint *ngIf="personForm.get('firstname').errors?.minlength" class="hint-error">
            {{'form.errors.minlength' | translate:{length:3} }}
        </mat-hint>
        <mat-hint *ngIf="personForm.get('firstname').errors?.maxlength" class="hint-error">
            {{'form.errors.maxlength' | translate:{length:60} }}
        </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'user.lastname' | translate}}</mat-label>
        <input matInput type="text" formControlName="lastname" tabindex="2" required />
        
        <mat-hint *ngIf="personForm.get('lastname').errors?.minlength" class="hint-error">
            {{'form.errors.minlength' | translate:{length:3} }}
        </mat-hint>
        <mat-hint *ngIf="personForm.get('lastname').errors?.maxlength" class="hint-error">
            {{'form.errors.maxlength' | translate:{length:60} }}
        </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'common.username' | translate}}</mat-label>
        <input matInput type="text" formControlName="username" tabindex="3" required />
        
        <mat-hint *ngIf="personForm.get('username').errors?.minlength" class="hint-error">
            {{'form.errors.minlength' | translate:{length:2} }}
        </mat-hint>
        <mat-hint *ngIf="personForm.get('username').errors?.maxlength" class="hint-error">
            {{'form.errors.maxlength' | translate:{length:60} }}
        </mat-hint>
    </mat-form-field>
    
    <mat-form-field appearance="fill">
        <mat-label>{{'common.email' | translate}}</mat-label>
        <input matInput type="email" formControlName="email" tabindex="4" />
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'common.roles' | translate}}</mat-label>
        <mat-select tabindex="5" formControlName="roles" multiple>
            <mat-option class="mat-select-panel" [value]="role.id" *ngFor="let role of roles">{{ role.name }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'common.badges' | translate}}</mat-label>
        <mat-select  tabindex="6" formControlName="badges" multiple>
            <mat-option class="mat-select-panel" [value]="badge.id" *ngFor="let badge of badges">{{ badge.name }}</mat-option>
            <mat-option value="" *ngIf="badges.length == 0">[T]no badges aviable</mat-option>
        </mat-select>
    </mat-form-field>


    <mat-form-field appearance="fill">
        <mat-label>{{'user.supervisor' | translate}}</mat-label>
        <mat-select tabindex="7" formControlName="supervisor_id">
            <mat-option class="mat-select-panel" value="0">-</mat-option>
            <mat-option [value]="user.id" *ngFor="let user of users">{{ user.username }}</mat-option>
        </mat-select>
    </mat-form-field>


    <!-- <br> -->
    <!-- <mat-checkbox formControlName="is_hidden" tabindex="7">{{'is_hidden'}}</mat-checkbox> -->
    <!-- <br> -->
    <div>
        <mat-checkbox formControlName="can_login" tabindex="8">{{ 'user.can_login' | translate }}</mat-checkbox>
        <mat-checkbox formControlName="is_supervisor" tabindex="9">{{ 'user.is_supervisor' | translate }}</mat-checkbox>
    </div>

    <div class="button-wrapper">
        <button mat-flat-button color="primary" type="submit" tabindex="9" (click)="onSubmit()" [disabled]="personForm.invalid">{{'common.submit' |
            translate}}</button>
        <button mat-button type="button" color="warn" tabindex="10" (click)="onCancel()">{{'common.close' | translate}}</button>
    </div>
</form>