<form [(formGroup)]="badgeForm" class="badge-form">
    <h2>{{(!!data? 'badge.modal.title.edit' : 'badge.modal.title.new') | translate}}</h2>

    <mat-form-field appearance="fill">
        <mat-label>{{'badge.name' | translate}}</mat-label>
        <input matInput type="text" formControlName="name" tabindex="1" required/>
        <mat-hint *ngIf="badgeForm.get('name').errors?.minlength" class="hint-error">
            {{'form.errors.minlength' | translate:{length:3} }}
        </mat-hint>
        <mat-hint *ngIf="badgeForm.get('name').errors?.maxlength" class="hint-error">
            {{'form.errors.maxlength' | translate:{length:60} }}
        </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'badge.rfid' | translate}}</mat-label>
        <input matInput type="text" formControlName="rfid" tabindex="2" required />
        <mat-hint style="font-size: 11px;" *ngIf="badgeForm.get('rfid').errors?.pattern" class="hint-error">
            {{ 'form.errors.pattern' | translate }}
        </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>{{'badge.user' | translate}}</mat-label>
        <mat-select tabindex="3" formControlName="user">
            <mat-option [value]="0">-</mat-option>
            <mat-option [value]="user.id" *ngFor="let user of users">{{ user.username }}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="button-wrapper">
        <button mat-flat-button color="primary" type="submit" tabindex="4" (click)="onSubmit()" [disabled]="badgeForm.invalid">{{'common.submit'| translate }}</button>
        <button mat-button type="button" color="warn" tabindex="5" (click)="onCancel()">{{'common.close'| translate }}</button>
    </div>
</form>