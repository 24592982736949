import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Filter } from 'src/app/models/filter';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { FilterService } from 'src/app/services/filter.service';
import { SnackbarService } from 'src/app/services/snackbar.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-password-setter',
  templateUrl: './password-setter.component.html',
  styleUrls: ['./password-setter.component.scss']
})
export class PasswordSetterComponent implements OnInit {
  rawUsers: User[] = [];
  users: User[] = [];
  $filter: Subscription = null;
  activeFilter: Filter = { roles: [], supervisors: [], text: '' };

  passwordSetForm = this.fb.group({
    users: [null, [Validators.required]],
    password: ['', [Validators.required, Validators.maxLength(50), Validators.minLength(8)]]
  });


  constructor(
    private authService: AuthService,
    private snackbarService: SnackbarService,
    private storageService: StorageService,
    private userService: UserService,
    private filterService: FilterService,
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.getUsers();

    this.$filter = this.filterService.getFilterObservable().subscribe((filter) => {
      this.activeFilter = filter;
      this.users = this.filterUsers(this.filterService.filterUsers(this.rawUsers, filter));
    });
  }

  ngOnDestroy(): void {
    if (this.$filter) {
      this.$filter.unsubscribe();
    }
  }


  onSubmit(): void {
    if (!this.passwordSetForm.invalid) {
      this.authService.passwordSet(this.passwordSetForm.getRawValue()).then(
        result => {
          console.log('success');
          this.snackbarService.openSnackBar({ text: 'Passwort wurde erfolgreich gesetzt!', color: 'success' });
        }
      ).catch(
        error => {
          this.snackbarService.openSnackBar({ text: 'Passwort konnte nicht gesetzt werden', color: 'error' });
          console.log('error: ');
          console.log(error);
        }
      );
    } else {
      console.error('not all fields filled as required');
    }
  }

  private getUsers(): void {

    this.users = this.filterService.filterUsers(this.filterUsers(this.storageService.getCacheUsers()), this.activeFilter);

    this.userService.getUsers().then(users => {
      this.storageService.setCacheUsers(users);
      this.rawUsers = users;
      this.users = this.filterService.filterUsers(this.filterUsers(this.rawUsers), this.activeFilter);
    });

  }


  private filterUsers(users: User[]): User[] {
    return users.filter(u => u.can_login);
  }

}
