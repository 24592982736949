<app-layout>
  <div class="container">
    <h1>{{ "common.roles" | translate }}</h1>
    <div id="spaceEvenly">
      <button
        mat-raised-button
        *hasPermission="[WRITE_ROLES]"
        [routerLink]="['/roles/newRole']"
      >
        {{ "create.role" | translate }}
      </button>
    </div>
    <table
      mat-table
      [dataSource]="roles"
      class="mytable dark-header"
      style="width: 100%; margin-top: 10px"
    >
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          {{ "roles.name" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <a [routerLink]="['/roles/membersOfRole', element.id]">
            {{ element.name }}
          </a>
        </td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef>
          {{ "roles.description" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <!-- Amount of Members Column -->
      <ng-container matColumnDef="amountOfMembers">
        <th mat-header-cell *matHeaderCellDef>
          {{ "common.amountOfMembers" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.users.length }}</td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ "common.actions" | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            class="c-pointer"
            color="primary"
            [routerLink]="['/roles/role', element.id]"
            >edit</mat-icon
          >
          <mat-icon class="c-pointer" color="warn" (click)="deleteRole(element)"
            >delete</mat-icon
          >
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsRoles"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsRoles"></tr>
    </table>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[10, 20, 50, 200]">
    </mat-paginator>
  </div>
</app-layout>
