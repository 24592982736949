<div class="wrapper">
    <div class="background-image"></div>
    <div class="login-wrapper">
      <mat-card class="login-card mat-elevation-z1">
        <mat-card-content>
          <h1 class="mat-h1">{{'common.password-reset' | translate | titlecase}}</h1>
          <form class="login-form" [(formGroup)]="passwordResetForm">
            <mat-form-field appearance="">
              <mat-label>{{'password-reset.password' | translate}}</mat-label>
              <input matInput type="password" formControlName="password" tabindex="1" autofocus="true" />
              
            </mat-form-field>
  
            <mat-form-field appearance="">
              <mat-label>{{'password-reset.password-repeat' | translate}}</mat-label>
              <input matInput type="password" formControlName="passwordrepeat" tabindex="2" />
              
            </mat-form-field>
  
            <button mat-raised-button color="primary" (click)="onSend()"
                disabled="{{(!passwordResetForm.dirty && passwordResetForm.valid)? 'true' : 'false'}}" tabindex="3">{{'common.send' | translate |
                uppercase}}</button>
            <br />
            <a routerLink="/auth/login" tabindex="4">{{'common.login' | translate}}</a>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>