import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/layout/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private _snackbar: MatSnackBar) { }

  public openSnackBar(data: {text: string, color: string} = {text: '', color: ''}) {
    this._snackbar.openFromComponent(SnackbarComponent, {
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      data: data,
      duration: 5 * 1000, //5 seconds
    });
  }
}
